import { View, Text, StyleSheet, Platform } from 'react-native'
import React from 'react'
import { Avatar } from '@rneui/base'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { _scale } from '../utils'
import { COLORS, ICONS, STRINGS } from '../constants'
import { teamListStyles } from '../styles/teamListStyle'
import moment from 'moment'


export default function TeamList(props) {
    
   const date = String(props.data.last_status).substring(0,10)
  
   const formattedDateStr = moment(date).format('DD-MM-YYYY')
    return (
        <TouchableOpacity style={[teamListStyles.mainBtn,{backgroundColor:props.color, borderLeftWidth:props.border,},{...props.mainBtn}]} onPress={props.onPressEmpName}>         
            <View style={[teamListStyles.container,{...props.container}]}>     
                {props.data.photo !== null && String(props.data.photo).length > 0 ?          
                    <Avatar
                        size='medium'
                        source={{ uri: STRINGS.imgUri + props.data.photo }}
                        containerStyle={teamListStyles.avatarView}
                        avatarStyle={teamListStyles.avatar}
                        imageProps={{ resizeMode: 'cover' }}
                        // icon={Platform.OS=='android'||Platform.OS=='ios'&&{ name: 'user', type: 'antdesign', color: COLORS.black, size: 24, }}
                    >
                        <Avatar.Accessory
                            Component={() => <View style={[teamListStyles.statusView, { backgroundColor: props.data.active == true ? 'green' : 'red', }]} />
                            } />
                    </Avatar> 
                    :
                    <View style={teamListStyles.profileIcon}>
                        <ICONS.AntDesign name='user' size={24} />
                        <View style={[teamListStyles.statusView, { backgroundColor: props.data.active == true ? 'green' : 'red', position: 'absolute', }]} />
                    </View>
                }
                <View style={[teamListStyles.nameView]}>
                    <Text style={[teamListStyles.names]}>{props.data.name}</Text>
                    {props.show && <Text style={teamListStyles.names}>{STRINGS.noOfStatus} <Text>{props.data.no_of_status}</Text> </Text>}
                    <Text style={teamListStyles.names}>{STRINGS.lastStatus} {formattedDateStr}</Text>
                </View>
                <Text style={[teamListStyles.technology]}>{String(props.data.technology).length > 0 ? props.data.technology : "NA"}</Text>
               
            </View>
            
        </TouchableOpacity>
    )
}