import { NavigationContainer } from '@react-navigation/native';
import { useEffect, useContext } from 'react';
import DashboardNavigator from './dashboard';
import AuthNavigator from './auth';
import { AuthContext } from '../context/CreateContext';
import AsyncStorage from '@react-native-async-storage/async-storage'
import APILoader from '../components/APILoader';
import { setIdToStorage } from '../services/storage/StorgeHelper';

function AppNavigation() {
    const authCtx = useContext(AuthContext)
    
    const getToken = async() => {      
       const token = await AsyncStorage.getItem('Token')
       if(token !== null){
            authCtx.authenticate(true)
       }
       else if(token == null){
            authCtx.authenticate(false)
       }
    }
    useEffect(() => {
        getToken()        
    }, [])
      
      return (
        <NavigationContainer>
            {authCtx.isAuthState == undefined ?<APILoader/> : authCtx.isAuthState == true ? <DashboardNavigator /> : <AuthNavigator />}
        </NavigationContainer>
    );
    }
 

export default AppNavigation;
