import { StyleSheet, View, Modal, TouchableOpacity, Text, Platform } from 'react-native'
import { COLORS, FONTS, STRINGS } from '../constants'
import { _scale } from '../utils'

export default function LogOut(props) {

    return (
        <Modal visible={props.showLogoutModal} animationType="fade" transparent={true}>
            <TouchableOpacity style={styles.modalContainer} onPress={props.handleModalPress}>
                <View style={[styles.modalContent,]}>
                    <Text style={styles.logout}>{STRINGS.logout}</Text>
                    <Text style={styles.modalText}>{STRINGS.logoutAlertMessage}</Text>
                    <View style={styles.buttonContainer}>
                        <TouchableOpacity style={[styles.cancelBtn]} onPress={props.handleCancel}>
                            <Text style={[styles.cancel]}>{STRINGS.cancel}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={[styles.logBtn,]} onPress={props.handleLogout}>
                            <Text style={[styles.logBtnText,]}>{STRINGS.logout}</Text>
                        </TouchableOpacity>
                    </View>
                </View>
            </TouchableOpacity>
        </Modal>
    )
}
const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        // justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginRight:_scale(20),
        marginTop:_scale(20)
        // backgroundColor: COLORS.transparentDark,

    },
    modalContent: {
        backgroundColor: COLORS.white,
        borderRadius: _scale(10),
        // borderTopLeftRadius: _scale(10),
        padding: _scale(20),
        // width: '100%',
        // height:'25%',
        // alignItems: 'center',
    },
    modalText: {
        fontSize: _scale(16),
        fontFamily: FONTS.Futura.medium,
        marginBottom: _scale(20),
        textAlign: 'center',
        color: COLORS.black
    },
    buttonContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        // width: '100%',
    },
    cancelBtn: {
        borderRadius: 5,
        padding: _scale(10),
        margin: _scale(10),
        flex: 1,
        alignItems: 'center',
        backgroundColor: COLORS.light
    },
    cancel: {
        color: COLORS.black,
        fontSize: _scale(16),
         fontFamily: FONTS.Futura.medium,
    },
    logBtn: {
        backgroundColor: COLORS.black,
        borderRadius: _scale(5),
        padding: _scale(10),
        margin: _scale(10),
        flex: 1,
        alignItems: 'center',
    },
    logout: {
        fontFamily: FONTS.Futura.semiBold,
        color: COLORS.black,
        fontSize: _scale(24)
    },
    logBtnText: {
        color: COLORS.white,
        fontSize: _scale(16),
        fontFamily: FONTS.Futura.medium,
    },
  
})