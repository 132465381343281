import MaterialCommunityIconsI from 'react-native-vector-icons/MaterialCommunityIcons'
import MaterialIconsI from 'react-native-vector-icons/MaterialIcons'
import FontAwesomeI from 'react-native-vector-icons/FontAwesome'
import AntDesignI from 'react-native-vector-icons/AntDesign'
import IoniconsI from 'react-native-vector-icons/Ionicons'
import EntypoI from 'react-native-vector-icons/Entypo'
import React from 'react'

export const MaterialCommunityIcons = props => (<MaterialCommunityIconsI {...props} />)
const MaterialIcons = props => <MaterialIconsI {...props} />
const FontAwesome = props => <FontAwesomeI {...props} />
const AntDesign = props => <AntDesignI {...props} />
const Ionicons = props => <IoniconsI {...props} />
const Entypo = props => <EntypoI {...props} />




export default {
    MaterialCommunityIcons,
    MaterialIcons,
    FontAwesome,
    AntDesign,
    Ionicons,
    Entypo,

}
