import { TouchableOpacity, FlatList, Text,View, Image, TextInput, StatusBar, Platform, Alert } from 'react-native'
import { getTeamList } from '../../../controllers/postTeamList'
import { COLORS, FONTS, ICONS, STRINGS } from '../../../constants'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { requests } from '../../../utils/requestParams'
import TeamList from '../../../components/FlatListTeam'
import { teamStyles } from '../../../styles/teamStyles'
import BackHeader from '../../../components/BackHeader'
import { appStyles } from '../../../styles/appStyles'
import APILoader from '../../../components/APILoader'
import { useContext, useEffect, useState } from 'react'
import Mailer from 'react-native-mail'
import { _scale } from '../../../utils'
import { CheckBox } from '@rneui/base'
import { AuthContext } from '../../../context/CreateContext'
import FlashMessage from '../../../utils/flashMessage'
import { setIdToStorage } from '../../../services/storage/StorgeHelper'

export default function MissingStatus({ navigation }) {
    
    const [isLoading, setIsLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [shouldShow, setshouldShow] = useState(false)
    const [selectedItems, setSelectedItems] = useState([])
    const [isChecked, setIsChecked] = useState(false)

    const [data, setData] = useState([])
    const authCtx = useContext(AuthContext)
    useEffect(() => {
        setIsLoading(true)
        getTeamList(requests.missingStatus).then((res) => {
            setData(res)
        }).catch((err) => {
            console.log(err)
            FlashMessage('Session Expired',1)
            setIdToStorage('')
            
            authCtx.authenticate(false)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])  

  
    
    const onChangeText = (text) => {
        setSearchQuery(text)
        if (text.trim().length > 0) {
            setshouldShow(true)
        }
        else if (text.trim().length == 0) {
            setshouldShow(false)
        }
    }
  
    const sendEmailToEmployees = (employees) => {
        let namesString = '';
        for (let i = 0; i < employees.length; i++) {
            console.log(employees[i+1] == employees.length);
            console.log(employees.length);
            if(i+1 == employees.length) { 
                namesString += employees[i].name 
            }
            else {   
                namesString += employees[i].name + ' , '
            };
            }
            let message = `Hi &nbsp;${namesString}<br><br>Please fill in your PMO status.<br><br>Thanks!`;
            console.log(message);   
        let emailList = employees.map((employee) => employee.email).join(',')
        if(emailList.length !== 0) { 
            Mailer.mail({
              subject: STRINGS.pmo,
              recipients: ['anuj.khandelwal@sofmen.com'],
              bccRecipients:[emailList],
              body: message,
              isHTML: true,
            }, (error, event) => {
                console.log(event)
              if(error) {
                console.log(error)
                Alert.alert(STRINGS.appName, STRINGS.error);
              }
            })}
    }
    const handleItemPress = (item) => {
        const index = selectedItems.findIndex((currValue) => currValue.id === item.id);
        if (index === -1) {      
            let temp = [...selectedItems];
            temp.push(item);
            setSelectedItems(temp);
        } else {
            if(isChecked){
                setIsChecked(false)
            }
            let temp = [...selectedItems];
            temp.splice(index, 1);
            setSelectedItems(temp);
        }
      };
    
      const handleSelectAllPress = () => {
        if (isChecked) {
          setSelectedItems([])
        } else {
          setSelectedItems(data)
        }
        setIsChecked(!isChecked);
      }
      
    const {top} = useSafeAreaInsets()

    const renderItem = (itemData) => {
        if (searchQuery === "") {
            return (<TeamList mainBtn = {{marginHorizontal: _scale(20),paddingHorizontal:_scale(10),}} data = {itemData.item}select={selectedItems.includes(itemData.item)?true:false} color={selectedItems.includes(itemData.item) ?COLORS.selectedColor : COLORS.light} border={selectedItems.includes(itemData.item)?4:0} onPressEmpName={() =>   {handleItemPress(itemData.item)}} />)
        }
        if (itemData.item.name.toUpperCase().includes(searchQuery.toUpperCase())) {
            return (<TeamList mainBtn = {{marginHorizontal: _scale(20),paddingHorizontal:_scale(10),}} data = {itemData.item}select={selectedItems.includes(itemData.item)?true:false} color={selectedItems.includes(itemData.item) ?COLORS.selectedColor : COLORS.light} border={selectedItems.includes(itemData.item)?4:0} onPressEmpName={() =>   {handleItemPress(itemData.item)}} />)
        }
    }
    return (
        <View style={[appStyles.safeAreaStyle,{backgroundColor:COLORS.appBlue}]}>
         <StatusBar barStyle={'light-content'} translucent={Platform.OS=='ios'}/>
            <View style={[teamStyles.container,{paddingTop:top}]}>
                {isLoading && <APILoader />}
                <BackHeader title={STRINGS.missing} navigation={navigation} />
                <View style={teamStyles.subContainer}>
                    <View style={teamStyles.innerView}>
                        <View style={teamStyles.inputView}>
                            <ICONS.AntDesign name='search1' size={18} color={COLORS.transparentGrey} style={{ marginLeft: 10 }} />
                            <TextInput
                                placeholder={STRINGS.search}
                                placeholderTextColor={COLORS.transparentGrey}
                                style={teamStyles.textInput}
                                onChangeText={(text) => onChangeText(text)}
                                value={searchQuery}
                            />
                            {shouldShow &&
                            <TouchableOpacity style={teamStyles.crossBtn} >
                              <ICONS.Ionicons name='close' size={14} color={COLORS.white}  onPress={() => { setSearchQuery(''); setshouldShow(false) }} />
                             </TouchableOpacity>
                            }
                        </View>
                        <TouchableOpacity style={teamStyles.iconView} onPress={()=>{sendEmailToEmployees( selectedItems)}} disabled={selectedItems.length==0}>
                            <ICONS.Ionicons name = {'mail'} size = {24} color = {'grey'}/>
                        </TouchableOpacity>
                    </View>
                    {isLoading == false && data.length==0 &&  <View style={teamStyles.noDataView}>
                        <Text style = {teamStyles.noData}> No data found </Text>
                        </View>}
                        {isLoading == false && data.length !== 0 && <CheckBox
                            checked={isChecked}
                            onPress={handleSelectAllPress}
                            containerStyle={teamStyles.checkboxContainer}
                            fontFamily={FONTS.Futura.medium}
                            title={STRINGS.selectAll}
                            titleProps={{marginLeft:2}}
                        />}
                    <FlatList
                        data={data}
                        style={{ flex: 1 }}
                        renderItem={renderItem}
                    />
                   
                </View>
            </View>
        </View>
    )
}

