import { Platform } from "react-native";

export default {

    Futura: {
        bold:'FuturaHeavy',
        semiBold: 'FuturaSemiBold',
        medium: 'FuturaMedium',
        book:'book',
    },

}
