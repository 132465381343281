import { View, ImageBackground, Modal, StyleSheet, TouchableOpacity, Text } from 'react-native'
import { COLORS, FONTS } from '../constants';

import { _scale } from '../utils';

export default function Filter(props) {
   
    return (
        <Modal
            animationType='fade'
            visible={props.modal}
            transparent={true}>
            <TouchableOpacity onPress={props.handleModalPress} style={{flex:1 }}>
                <ImageBackground blurRadius={90} style={styles.modalView} >
                    <View style={styles.gifContainer}>
                        <TouchableOpacity style={styles.innerView} onPress={props.allMemb}>
                            <Text style={styles.filterText}>All</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.innerView} onPress={props.activeMemb}>
                            <Text style={styles.filterText}>{props.secondFilter}</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={props.thirdFilter=='Inactive'?styles.btn: styles.innerView} onPress={props.inActiveMemb}>
                            <Text style={styles.filterText}>{props.thirdFilter}</Text>
                        </TouchableOpacity>
                      {props.fixbid &&  <TouchableOpacity style={styles.innerView} onPress={props.fixedBid}>
                            <Text style={styles.filterText}>{props.fixbid}</Text>
                        </TouchableOpacity>}
                        {props.bill &&  <TouchableOpacity style={styles.innerView}  onPress={props.billable}>
                            <Text style={styles.filterText}>{props.bill}</Text>
                        </TouchableOpacity>}
                        {props.billTM &&  <TouchableOpacity style={styles.innerView} onPress={props.billableTM}>
                            <Text style={styles.filterText}>{props.billTM}</Text>
                        </TouchableOpacity>}
                        {props.train &&  <TouchableOpacity style={styles.innerView} onPress={props.training}>
                            <Text style={styles.filterText}>{props.train}</Text>
                        </TouchableOpacity>}
                        {props.intern &&  <TouchableOpacity style={styles.innerView} onPress={props.internal}>
                            <Text style={styles.filterText}>{props.intern}</Text>
                        </TouchableOpacity>}
                        {props.excess &&  <TouchableOpacity style={styles.btn} onPress={props.excessHours}>
                            <Text style={styles.filterText}>{props.excess}</Text>
                        </TouchableOpacity>}
                      
                    </View>
                </ImageBackground>
            </TouchableOpacity>
        </Modal>
    )
}
const styles = StyleSheet.create({
    modalView: {
        flex: 1,
        width: '100%',
        alignItems:'flex-end',
        backgroundColor:COLORS.transparent,
        paddingTop:_scale(50),
        paddingLeft: _scale(250),

    },
    btn:{ 
        padding:_scale(15),
    },
    gifContainer: {
        
        borderRadius: _scale(8),
        backgroundColor: COLORS.white,
        margin: _scale(10),
        paddingVertical: _scale(10),

    },
    innerView: {
        padding: _scale(15),
        borderBottomWidth: 1,
        borderColor:COLORS.lighterblack,
        paddingRight:_scale(30),

    },
    filterText: {
        color: COLORS.black,
        fontFamily: FONTS.Futura.medium,
    }

});
