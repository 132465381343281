export default class User {
    constructor(data) {
      this.id = data.id||''
      this.commonName = data.common_name||''
      this.firstName = data.first_name||''
      this.lastName = data.last_name||''
      this.email = data.email||''
      this.dateJoined = new Date(data.date_joined)||''
      this.lastLogin = new Date(data.last_login)||''
      this.isActive = data.is_active||''
      this.isQbnewb = data.is_qbnewb||''
      this.isSuperuser = data.is_superuser||''
      this.groupIds = data.group_ids||''
      this.personalCollectionId = data.personal_collection_id||''
      this.locale = data.locale||''
      this.loginAttributes = data.login_attributes||''
      this.googleAuth = data.google_auth||''
      this.ldapAuth = data.ldap_auth||''
      this.updatedAt = new Date(data.updated_at)||''
    }
  }