import { LOCAL_STORAGE } from ".."
import { STRINGS } from "../../constants"

export const setIdToStorage = (id) => {
    LOCAL_STORAGE.setItem(STRINGS.id, id)
}

export const getIdFromStorage = () => {
    return LOCAL_STORAGE.getItem(STRINGS.id)
}


