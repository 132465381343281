import { API } from "../services";

import Tasklist from "../models/TaskListModel";
import { mergeRowsColumns } from "../utils";
import { END_POINTS } from "../constants";
var api = API.getInstance()

export const getTaskList = async (request) => new Promise((resolve, reject) => {

    api.onPostApi(END_POINTS.getList, request).then((response) => {
        const data = mergeRowsColumns(response.json['data'])
        let responseModel = new Tasklist().initWithRes(data)
        resolve(responseModel)
    }).catch((error) => {
        reject(error)
    })
})
