import { StyleSheet } from "react-native";
import { _scale } from "../utils";
import { COLORS, FONTS } from "../constants";

export const homeStyles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.appBlue,
    },

    secondView: {
        flex: 1,
        backgroundColor: COLORS.light,
        borderTopLeftRadius: _scale(20),
        borderTopRightRadius: _scale(20),
    },

    text: {
        fontSize: _scale(14),
        fontFamily: FONTS.Futura.semiBold,
        color: COLORS.black
    },
    profileIcon: {
        height: _scale(50),
        width: _scale(50),
        backgroundColor: COLORS.lighterBlue,
        borderRadius: _scale(50),
        marginTop: _scale(20),
        marginLeft: _scale(20),
        alignItems: 'center',
        justifyContent: 'center',
    },
    titleView: {
        justifyContent: 'space-between',
        flexDirection: 'row',
       
    },
    avatarStyle: {
        marginLeft: _scale(20),
        marginTop: _scale(20),
        backgroundColor: COLORS.lighterBlue
    },
    userNameView: {
      justifyContent:'center',
      marginHorizontal:_scale(10),
      flex:1
    },
    userName: {
        color: COLORS.white,
        fontSize: _scale(18),
        fontFamily: FONTS.Futura.medium,
       
    },
    imgBack: {
        height: '100%',
        width: '100%'
    },

    logOutBtn: {
        margin: _scale(20),
        backgroundColor: COLORS.lighterBlue,
        padding: _scale(10),
        borderRadius: _scale(8),
        alignItems: 'center'
    },
    title: {
        color: COLORS.white,
        fontSize: _scale(30),
        fontFamily: FONTS.Futura.bold,
        margin: _scale(20)
    },
    subText: {
        color: COLORS.black,
        fontFamily: FONTS.Futura.medium,
        fontSize:_scale(16)
    },
    dashboardView: {
        justifyContent:'center',
        marginHorizontal:_scale(20),
        
    },
    dashboard: {
        color: COLORS.white,
        fontSize: _scale(34),
        fontFamily: FONTS.Futura.bold,
    },
    cardView: {
        padding: _scale(20),
        backgroundColor: COLORS.white,
        marginHorizontal: _scale(20),
        marginTop: _scale(20),
        borderRadius: _scale(23),
       
        height:_scale(170)
        

    },
    cardTitleView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop:_scale(7)
        
    },
    cardTitle: {
        fontSize: _scale(28),
        color: COLORS.black,
        fontFamily: FONTS.Futura.semiBold,
       

    },
    iconView: {
        backgroundColor: COLORS.skyBlue,
        borderRadius: _scale(10),
        width: _scale(45),
        height: _scale(45),
        alignItems: 'center',
        justifyContent: 'center'
    },
    arrowView: {
        backgroundColor: COLORS.black,
        borderRadius: _scale(10),
        width: _scale(40),
        height: _scale(40),
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: _scale(8)
    }
})