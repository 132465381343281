
export default {
    appName: 'Analytics Metabase',
    emailRegex: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    login: 'Login',
    logoutAlertMessage: 'Are you sure you want to logout?',
    pmo:'PMO Reminder',
    welcome:'Welcome Back',
    subWelcomeText:'Enter your details below',
    email:'Email',
    password:'Password',
    billable:'Billable',
    team:'Team',
    teams:'Teams',
    search:'Search',
    projects:'Projects',
    projectDetails: "Project Summary",
    active:'Active',
    inactive:'Inactive',
    imgUri:'https://pmo.sofmen.com/uploads/users/',
    id:'id',
    noOfStatus:'No of Status :',
    lastStatus:'Last Status Date :',
    logout:'Logout',
    cancel:'Cancel',
    userTimeReport:' User Time Report',
    noData:'No Data Found',
    alloted:'Total time alloted',
    spent:'Total time spent',
    days:'days',
    sofmen:'Sofmen',
    tagLine:'Innovate. Disrupt. Lead',
    teamsSubText:'Meet the people behind the code',
    projectsSubText:"Discover what we're building",
    fixedBid:'Fixed Bid',
    open:'Open',
    error:'Could not send email. Please try again later.',
    taskAsgn:'Task Assign',
    description: '<p>Today status :<br /> working on result section and add column condition based showing result status <br />add column for email send functionality in result section table</p>',
    appNotSupported:"This service is not supported in your device.",
    missing:"Missing Status",
    missingSubText:'Track missing project updates',
    selectAll:'Select All',
    pdf: 'PDF Downloaded',
    projectReport:'Project Report',
   projectD:"Project Detail"
    

};
export const htmlUser = (item,photo,name,email,mobile)=>{
   const header = `<!DOCTYPE html>
    <html lang="en">
    <head>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet">
    </head>
    <style>
      
      body * {
        box-sizing: border-box;
        font-family:Poppins ;
    }
    </style>
    <body>
        <div style="padding: 24px;">`
    const footer = `</div></body></html>`
    return`${header}<div>
    <div style="width: 100%;background-color: #1B3E61;padding: 20px;color: #fff;border-radius: 16px;">
        <div style="display: flex;">
          <div style="width: 100px;height: 100px;">
            <img src=${'https://pmo.sofmen.com/uploads/users/'}${photo} alt="profile" width="100px" style="width: 100px;
            height: 100px;
            object-fit: cover;border-radius: 8px;">
          </div>
          <div style="margin-left: 12px;">
            <h1 style="margin: 0px;">${name}</h1>
            <p style="margin: 0px;font-size: 16px;">${email}</p>
            <p style="margin: 0px;font-size: 16px;">${mobile}</p>
          </div>
        </div>
      </div>
      <div>
  <div style="margin-top: 10px;margin-bottom:5px;padding: 4px 0px;">
    <h1>User Time Report</h1>
  </div>
 
    <div>
   
    ${item.map((i) => {
        let t = 0
         i.details.map((i) => t += parseFloat(i.worked_hours))
        return `<div style="border:1px solid #eeeeee;margin-top:15px;border-radius: 12px;margin-bottom: 20px;">
          <div style="display: flex;background-color: #F3F8FE;padding: 16px;border-radius: 12px 12px 0px 0px;">
            <div style="width: 50%;">
              <label style="font-size: 16px;">Date</label>
              <h4 style="margin: 0px;padding-top: 6px;font-size: 18px;">${i.date}</h4>
            </div>
            <div style="width: 50%;text-align: end;">
              <label style="font-size: 16px;">Hours</label>
              <h4 style="margin: 0px;padding-top: 6px;font-size: 18px;">${t}</h4>
            </div>
          </div>
          ${i.details.map((j) => {
            return `
              <div style="display: flex;padding: 16px;">
                <div style="width: 50%;">
                  <h4 style="margin: 0px;padding-top: 6px;font-size: 16px;">${j.name}</h4>
                </div>
                  <div  style="width: 50%;text-align: end;">      
                    <h4 style="margin: 0px;padding-top: 6px;font-size: 16px;">${j.worked_hours}</h4>
                  </div>
              </div>
              <div style="padding:0px 16px;">
              <p style="font-size: 12px;">${j.description_3}</p>
            </div>
            `
          }).join("")}
     </div>      `
      }).join("")}
  
  </div>
</div>
</div>${footer}`
}


export const htmlProject =(projectData,data)=>{

const header = `<!DOCTYPE html>
<html lang="en">
  <head>
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"
      rel="stylesheet"
    />
  </head>
  <style>
    body * {
      box-sizing: border-box;
      font-family: Poppins;
    }
  </style>
  <body> 
      <div style="padding: 24px">`
 const footer=`</div></body></html>`
     return `${header}<div>
${projectData.map((i) => {
  return `
  <div
  style="
      width: 100%;
      background-color: #1b3e61;
      padding: 20px;
      color: #ffffff;
      border-radius: 16px;
  "
  >
<div style="display: flex">
<div style="width: 70%">
<h1 style="margin: 0px; font-size: 24px">${i.name}</h1>
</div>
<div style="width: 30%; text-align: end">
<span
  style="
    background: #dcfeec;
    color: #0d9834;
    font-size: 14px;
    padding: 8px 16px;
    border-radius: 8px;
  "
  >${i.project_status}</span
>
</div>
</div>
<div style="width: 100%; padding: 5px">
<div style="display: flex; margin-top: 20px">
<div style="width: 33.33%">
  <label style="font-size: 12px; font-weight: 300">Project Type</label>
  <p style="margin: 0px; font-size: 16px; font-weight: 600">
    ${i.project_type}
  </p>
</div>
<div style="width: 33.33%">
  <label style="font-size: 12px; font-weight: 300">Group Name</label>
  <p style="margin: 0px; font-size: 16px; font-weight: 600">
    ${i.group_name}
  </p>
</div>
<div style="width: 33.33%">
  <label style="font-size: 12px; font-weight: 300">Start Date</label>
  <p style="margin: 0px; font-size: 16px; font-weight: 600">
    ${i.start_date}
  </p>
</div>
</div>

<div style="display: flex; margin-top: 20px">
<div style="width: 33.33%">
  <label style="font-size: 12px; font-weight: 300"
    >Total time allotted</label
  >
  <p style="margin: 0px; font-size: 16px; font-weight: 600">
    ${i.total_estimate} hrs
  </p>
</div>
<div style="width: 33.33%">
  <label style="font-size: 12px; font-weight: 300"
    >Total Time spent</label
  >
  <p style="margin: 0px; font-size: 16px; font-weight: 600">
    ${i.total_hours_spent} hrs
  </p>
</div>
<div style="width: 33.33%">
  <label style="font-size: 12px; font-weight: 300"
    >Total time spent (7 days)</label
  >
  <p style="margin: 0px; font-size: 16px; font-weight: 600">
    ${i.total_hours_in_7_days} hrs
  </p>
</div>
</div>
</div>
  </div>

  
    <div style="margin: 10px; padding: 4px 0px">
      <h1>Project Report</h1>
    </div>
    <div>
      ${data.map((item) => {
        let t=0
        item.details.map((i) => t += parseFloat(i.worked_hours))
        return `
          <div style="border: 1px solid #eeeeee; border-radius: 12px; margin-bottom: 20px">
            <div style="display: flex; background-color: #f3f8fe; padding: 16px; border-radius: 12px 12px 0px 0px;">
              <div style="width: 50%">
                <label style="font-size: 16px">Date</label>
                <h4 style="margin: 0px; padding-top: 6px; font-size: 20px">${item.date}</h4>
              </div>
              <div style="width: 50%; text-align: end">
                <label style="font-size: 16px">Hours</label>
                <h4 style="margin: 0px; padding-top: 6px; font-size: 20px">${t}</h4>
              </div>
            </div>
            ${item.details.map((detail) => {
               
              return `
                <div style="border-bottom: 1px solid #f3f8fe">
                  <div style="display: flex; padding: 16px">
                    <div style="width: 50%">
                      <h4 style="margin: 0px; padding-top: 6px; font-size: 24px">
                        ${detail.name_2}
                      </h4>
                    </div>
                    <div style="width: 50%; text-align: end">
                      <h4 style="margin: 0px; padding-top: 6px; font-size: 24px">
                        ${detail.worked_hours}
                      </h4>
                    </div>
                  </div>
                  <div style="padding: 0px 16px; border-bottom: 1px solid #f3f8fe">
                    <p style="font-weight: 600">Task: ${detail.name}</p>
                    <p>${detail.description}</p>
                  </div>
                </div>
              `;
            }).join('')}
          </div>
        `;
      }).join('')}
    </div>
  `;
}).join('')}
</div>${footer}`}