import React, { useState } from 'react'
// import AsyncStorage from '@react-native-async-storage/async-storage'
import { AuthContext } from './CreateContext'
import { getIdFromStorage } from '../services/storage/StorgeHelper';

export default function AuthProvider({ children }) {
  const [authState, setAuthState] = useState(undefined)


  function authenticate(state) {
    setAuthState(state)

  }

  function logout() {
    setAuthState(false)
  }

  const value = {
    isAuthState: authState,
    authenticate: authenticate,
    logout: logout
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
}