import { StyleSheet } from "react-native";
import { _scale } from "../utils";
import { COLORS, FONTS } from "../constants";

export const teamStyles = StyleSheet.create({
    container: {
        flex: 1,
        
    },
    header:{
        backgroundColor: COLORS.appBlue,
        justifyContent:'space-between',
        flexDirection:'row',
        alignItems:'center'
    },
    subContainer:{ 
        flex: 1 ,
        backgroundColor: COLORS.light, 
    },
    innerView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginHorizontal: _scale(20),
        marginVertical: _scale(20),
    },
    inputView: {
        alignItems: 'center',
        flexDirection: 'row',
        flex: 1,
        borderRadius: _scale(10),
        backgroundColor: COLORS.white,
        marginRight: _scale(10),
        height: _scale(40),
        elevation:4,
        shadowColor:COLORS.grey,
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    },

    textInput: {
        fontFamily: FONTS.Futura.book,
        paddingHorizontal: _scale(10),
        paddingVertical:_scale(10),
        color: COLORS.transparentGrey,
        flex: 1,
        marginRight: _scale(10),
        fontSize:_scale(15.5),
        lineHeight:_scale(18)

    },
    iconView: {
        backgroundColor: COLORS.white,
        height: _scale(40),
        width: _scale(40),
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: _scale(10),
        elevation:4,
        shadowColor:COLORS.grey,
        shadowOffset: {
            width: 0,
            height: _scale(2),
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
    },
    modalItem: {
        padding: _scale(5),
    },
    namedFilters: {
        fontFamily: FONTS.Futura.medium,
        fontSize: _scale(16),
        color: COLORS.black,
        marginVertical: _scale(20),
        marginHorizontal: _scale(10),
    },
    crossBtn: {
        backgroundColor: COLORS.lightgrey,
        padding: _scale(3),
        borderRadius: _scale(70),
        height: _scale(20),
        width: _scale(20),
        marginRight: _scale(10),
        alignItems: 'center',
        justifyContent: 'center'
    },
    checkboxContainer: {
        backgroundColor: 'transparent',
        borderWidth: 0,
        paddingHorizontal: 0,
        paddingVertical:0,
        paddingBottom:_scale(10),
        alignSelf:'flex-end'
      },
      checkboxText: {
        fontSize: _scale(16),
        marginLeft: _scale(8),
        fontFamily:FONTS.Futura.semiBold,
      },
      noDataView:{
        alignItems:'center',
        justifyContent:'center',
        margin:_scale(20),
        flex:2
    },
      noData:{
        fontFamily:FONTS.Futura.semiBold,
        fontSize:_scale(17),
        color:COLORS.black,
        textAlign:'center'
    },
})