import { Platform, StyleSheet } from "react-native";
import { COLORS, FONTS } from "../constants";
import { _scale } from "../utils";

export const loginStyles = Platform.OS=='web' ?
StyleSheet.create({
    mainView:{ 
        backgroundColor: COLORS.appBlue, 
        flex: 1 ,
      
    },
   
    loginMainView: {
        margin: _scale(20),
        
    },

    innerView: {
        backgroundColor: COLORS.light,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        flex: 1,
    },
    title: {
        fontFamily: FONTS.Futura.semiBold,
        fontSize: _scale(32),
        color: COLORS.black,
        marginTop: _scale(60),

    },
    subtitle: {
        fontFamily: FONTS.Futura.medium,
        color: COLORS.grey,
        fontSize:_scale(14),
        margin: _scale(10)
    },
    button: {
        backgroundColor: COLORS.black,
        padding: _scale(16),
        alignItems: 'center',
        borderRadius: _scale(12),
        marginVertical:_scale(20)
    },
    inputView: {
        flexDirection: 'row',
        marginBottom: _scale(24),
        borderRadius: _scale(12),
        backgroundColor: COLORS.white,
        alignItems: 'center',
        justifyContent: 'center',
       
    },

    textInput: {
        fontFamily: FONTS.Futura.book,
        color: COLORS.black,
        paddingVertical: _scale(12),   
        flex: 1,
        marginRight:_scale(20)
    },
   
    login: {
        color: COLORS.white,
        fontFamily: FONTS.Futura.semiBold,
        fontSize: _scale(16)
    },
    icon:{ 
        margin: _scale(10)
    },
    emailLabel:{
        color: COLORS.black,
        fontFamily:FONTS.Futura.book,
    },})
    : StyleSheet.create({
    mainView:{ 
        backgroundColor: COLORS.appBlue, 
        flex: 1 
    },
   
    loginMainView: {
        margin: _scale(20)
    },

    innerView: {
        backgroundColor: COLORS.light,
        borderTopLeftRadius: 20,
        borderTopRightRadius: 20,
        flex: 1,
    },
    title: {
        fontFamily: FONTS.Futura.semiBold,
        fontSize: _scale(32),
        color: COLORS.black,
        marginTop: _scale(60),

    },
    subtitle: {
        fontFamily: FONTS.Futura.medium,
        color: COLORS.grey,
        fontSize:_scale(14),
        margin: _scale(10)
    },
    button: {
        backgroundColor: COLORS.black,
        padding: _scale(16),
        alignItems: 'center',
        borderRadius: _scale(12),
        marginVertical:_scale(20)
    },
    inputView: {
        flexDirection: 'row',
        marginBottom: _scale(24),
        borderRadius: _scale(12),
        backgroundColor: COLORS.white,
        alignItems: 'center',
        justifyContent: 'center'
    },

    textInput: {
        fontFamily: FONTS.Futura.book,
        color: COLORS.black,
        paddingVertical: _scale(12),   
        flex: 1,
        marginRight:_scale(20)
    },
   
    login: {
        color: COLORS.white,
        fontFamily: FONTS.Futura.semiBold,
        fontSize: _scale(16)
    },
    icon:{ 
        margin: _scale(10)
    },
    emailLabel:{
        color: COLORS.black,
        fontFamily:FONTS.Futura.book,
    },
//Text

})