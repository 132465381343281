import {projectDetailRequest,projectDetailWithID,} from "../../../utils/requestParams"
import { getProjectNameApi } from "../../../controllers/getProjectNameController"
import { projectDetailApi } from "../../../controllers/projectDetailController"
import { View, Text, FlatList, Dimensions, Alert } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { memCellStyles } from "../../../styles/memDetailCellStyles"
import { COLORS, FONTS, ROUTES, STRINGS } from "../../../constants"
import { memDetailStyles } from "../../../styles/userDetailStyles"
import ProjectList from "../../../components/FlatListProjects"
import { useNavigation } from "@react-navigation/native"
import { htmlProject } from "../../../constants/appStrings"
import { _scale, decodeHtmlentities } from "../../../utils"
import BackHeader from "../../../components/BackHeader"
import APILoader from "../../../components/APILoader"
import RNHTMLtoPDF from "react-native-html-to-pdf"
import { appStyles } from "../../../styles/appStyles"
import { useEffect, useState } from "react"
import moment from "moment"

const { width } = Dimensions.get("screen")
export default function ProjectSummary({ route }) {
  const navigation = useNavigation()
  const [data, setData] = useState([])
  const [projectData, setProjectData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [id, setId] = useState(route.params.id)
  const { top } = useSafeAreaInsets()

  useEffect(() => {
    setIsLoading(true)
    getProjectNameApi(projectDetailWithID(id))
      .then((res) => {
        const data = res
        const updated = data.map((item) => ({
          ...item,
          total_estimate: item.total_estimate * 8,
        }))
        setProjectData(updated)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {})
    projectDetailApi(projectDetailRequest(id))
      .then((response) => {
        const combinedData = response.reduce((acc, cur) => {
          const dateString = String(cur.created_at).substring(0, 10)
          const existingData = acc.find((elem) => elem.date === dateString)
          if (existingData) {
            existingData.details.push(cur)
          } else {
            acc.push({ date: dateString, details: [cur] })
          }
          return acc
        }, [])
        setData(combinedData)
      })
      .catch((err) => {
        console.log("Error", err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [navigation])
  async function createPDF() {
    try {
      setIsLoading(true)
      let options = {
        html: htmlProject(projectData, data),
        fileName: "Project_Report",
        directory: "Documents",
      }

      let file = await RNHTMLtoPDF.convert(options)
      setIsLoading(false)
      console.log(file.filePath)

      let shareOptions = {
        url: `file://${file.filePath}`,
        type: "application/pdf",
        message: STRINGS.projectReport,
        title: "Share PDF",
      }

      Alert.alert(STRINGS.pdf, `File path: ${file.filePath}`, [
        {
          text: "OK",
          onPress: () => console.log("OK pressed"),
        },
        {
          text: "Share",
          onPress: async () => {
            // const shareResult = await Share.open(shareOptions)
            // console.log(shareResult)
          },
        },
      ])
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      Alert.alert("Error", error.message)
    }
  }
  const onPressEmployee = () => {
    navigation.push(ROUTES.EMPDETAILS, {
      id: object.created_by,
      routeName: "Project",
    })
  }

  const renderItem = ({ item }) => {
    let t = 0
    item.details.map((i) => (t += parseFloat(i.worked_hours)))
    const dateString = item.date
    const formattedDate = moment(dateString).format("MMM DD,YYYY")
    return (
      <View style={appStyles.mainView}>
        <View style={[memDetailStyles.thirdView, { backgroundColor: COLORS.light }]}>
          <View style={[memCellStyles.cardView, { backgroundColor: COLORS.white }]} >
            <View style={memCellStyles.cardInnerView}>
              <Text style={memCellStyles.header}>{formattedDate}</Text>
              <Text style={{fontFamily: FONTS.Futura.semiBold}}>{t} {t > 1 ? "Hrs" : "Hr"}</Text>
            </View>
            <View style={memCellStyles.divider} />
            {item.details.map((object, index) => {
              const isLastItem = index === item.details.length - 1
              return (
                <View style={appStyles.mainView}>
                  <View style={memCellStyles.innerView}>
                    <Text style={memCellStyles.content}>
                      <Text onPress={() => { navigation.push(ROUTES.EMPDETAILS, {id: object.created_by, routeName: "Project",})}}  style={memDetailStyles.employee}>{object.name_2}</Text>
                    </Text>
                    <Text style={memCellStyles.workedHrs}>{object.worked_hours}{" "}{object.worked_hours > 1 ? "hrs" : "hr"}
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", marginTop: _scale(10) }}>
                    <Text style={memCellStyles.task}>Task : {object.name}</Text>
                  </View>
                  <View style={memCellStyles.innerDesView}>
                    <Text style={memCellStyles.description}>
                      {decodeHtmlentities(object.description)
                        .replace(/\s+/g, " ")
                        .trim()
                        .replace(/<[^>]+>/g, "")
                        .replace(/&rsquo/g, "'")}
                    </Text>
                  </View>
                  {!isLastItem && <View style={[memCellStyles.divider]} />}
                </View>
              )
            })}
          </View>
        </View>
      </View>
    )
  }

  return (
    <View style={{ flex: 1, backgroundColor: COLORS.appBlue }}>
      <View style={{ marginTop: top, flex: 1 }}>
        {isLoading && <APILoader />}
        <BackHeader
          navigation={navigation}
          title={STRINGS.projectD}
          third
          onPressPdf={() => {
            createPDF()
          }}
        />
        <View style={{ flex: 1, backgroundColor: COLORS.light }}>
          {isLoading == false && projectData.length == 0 && (
            <View
              style={{
                alignItems: "center",
                justifyContent: "center",
                margin: 20,
                flex: 2,
              }}
            >
              <Text
                style={{
                  fontFamily: FONTS.Futura.semiBold,
                  fontSize: _scale(17),
                  color: COLORS.black,
                  textAlign: "center",
                }}
              >
                {" "}
                No data found{" "}
              </Text>
            </View>
          )}
          <View style={{ flex: 1 }}>
            <FlatList
              data={projectData}
              style={{ flex: 1, paddingVertical: _scale(20) }}
              renderItem={({ item, index }) => {
                return (
                  <View style={{ flex: 1 }}>
                    <ProjectList
                      startDate={true}
                      progressBar={true}
                      alloted={true}
                      seven={true}
                      spent
                      index={index}
                      unfilledColor={
                        item.total_hours_spent <= item.total_estimate
                          ? COLORS.lightgrey
                          : item.total_hours_spent == item.total_estimate
                          ? COLORS.parrotGreen
                          : COLORS.tomatoRed
                      }
                      progress={
                        item.total_hours_spent <= item.total_estimate
                          ? item.total_hours_spent / item.total_estimate
                          : 1 -
                            (item.total_hours_spent - item.total_estimate) /
                              item.total_estimate
                      }
                      onPressProjectName={() =>
                        navigation.navigate(ROUTES.PROJECTSUMMARY, {
                          id: item.id,
                          name: item.name,
                        })
                      }
                    
                      data={item}
                      spentColor={
                        item.total_hours_spent <= item.total_estimate
                          ? COLORS.parrotGreen
                          : COLORS.tomatoRed
                      }
                      statusViewColor={
                        item.project_status == STRINGS.open
                          ? COLORS.lightOrange
                          : COLORS.lightBlue
                      }
                      statusColor={
                        item.project_status == STRINGS.open
                          ? COLORS.darkOrange
                          : COLORS.darkBlue
                      }
                    />

                    <FlatList
                      data={data}
                      style={{ flex: 1, marginBottom: _scale(20) }}
                      renderItem={renderItem}
                    />
                    {isLoading == false && data.length == 0 && (
                      <View style={appStyles.noDataView}>
                        <Text style={appStyles.noDataFound}>
                          Project {item.project_status} & no data found in last
                          60 days
                        </Text>
                      </View>
                    )}
                  </View>
                )
              }}
            />
          </View>
        </View>
      </View>
    </View>
  )
}
