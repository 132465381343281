import { ImageBackground,  ScrollView,  Text, TouchableOpacity, View} from 'react-native'
import { getIdFromStorage, setIdToStorage } from '../../../services/storage/StorgeHelper'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { COLORS, ICONS, ROUTES, STRINGS, URLS } from '../../../constants'
import { getCurrentUser } from '../../../controllers/currentUser'
import { useContext, useEffect, useState } from 'react'
import AppDivider from '../../../components/AppDivider'
import { homeStyles } from '../../../styles/homeStyles'
import APILoader from '../../../components/APILoader'
import { appStyles } from '../../../styles/appStyles'
import LogOut from '../../../components/LogOut'
import { _scale } from '../../../utils'
import IMAGES from '../../../assets'
import {API_URL,API_KEY} from '@env'
import { AuthContext } from '../../../context/CreateContext'
import Card from '../../../components/Card'


export default function Home({ navigation }) {
  const [user, setUser] = useState({})
  const [showLogoutModal, setShowLogoutModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const auth = useContext(AuthContext)

  useEffect(() => {
    let id = getIdFromStorage() 
    if(id.length > 0){
      getCurrentUser().then(res => {
        setUser(res);
      }).catch(err => {
        console.error('Error fetching user:', err);
        setIsLoading(false);
        setShowLogoutModal(false);
      }).finally(() => {
        setIsLoading(false);
      });
      
      
    }
    else{
      setIdToStorage('')
      AsyncStorage.removeItem('Token')
      auth.authenticate(false)
    }
    
  }, [])

 
  const handleLogout = () => {
    setIdToStorage('')
    AsyncStorage.removeItem('Token')
    auth.authenticate(false)
    setShowLogoutModal(false)
  };
  const handleCancel = () => {
    setShowLogoutModal(false);
  };
  const handleModalPress = () => {
    setShowLogoutModal(false)
  }
  const {top} = useSafeAreaInsets()

  return ( 
    <View style={[appStyles.safeAreaStyle]}>
     <View style={[homeStyles.container,{paddingTop:top}]}>
      {isLoading && <APILoader />}
        <View>
        <ImageBackground source={IMAGES.backSofmenImg} >
          <View style={homeStyles.titleView}>
            <View style={{ flexDirection: 'row' ,flex:1}}>
              <View style={homeStyles.profileIcon}>
                <Text style={homeStyles.text}>{user.commonName !== undefined && String(user.commonName).split(' ').reduce((acc, word, i) => i < 2 ? acc + word.charAt(0).toUpperCase() : acc, '')}</Text>
              </View>
              <View style={homeStyles.userNameView}>
                <Text style={homeStyles.userName}>{user.commonName !== undefined && String(user.commonName)}</Text>
              </View>
            </View>
            <TouchableOpacity style={homeStyles.logOutBtn} onPress={() => {console.log("A");setShowLogoutModal(true)}}>
              <ICONS.AntDesign name='logout' size={20} color={COLORS.white} />
            </TouchableOpacity>
          </View>
          <View style={homeStyles.dashboardView}>
            <Text style={homeStyles.dashboard}>{STRINGS.sofmen}</Text>
            <Text style={[homeStyles.subText, { color: COLORS.white, marginLeft: 4 }]}>{STRINGS.tagLine}</Text>
          </View>
          </ImageBackground>
        </View>
      <View style={{flex:1,marginTop:_scale(30)}}>
        <AppDivider/>
        <View style={homeStyles.secondView}>
          <ScrollView style={{flexGrow:1}}>
          <TouchableOpacity style={[homeStyles.cardView,]} onPress={() => {navigation.navigate(ROUTES.TEAMS)}}>
            <ImageBackground source={IMAGES.stars} style={homeStyles.imgBack} >
              <View style={[homeStyles.iconView, { backgroundColor: COLORS.skyBlue }]}>
                <ICONS.FontAwesome name='users' size={24} color={COLORS.white} />
              </View>
              <View style={homeStyles.cardTitleView}>
                <View>
                  <Text style={homeStyles.cardTitle}>{STRINGS.teams}</Text>
                  <Text style={homeStyles.subText}>{STRINGS.teamsSubText}</Text>
                </View>
                <View style={homeStyles.arrowView}>
                  <ICONS.AntDesign name='arrowright' size={24} color={COLORS.white} />
                </View>
              </View>
            </ImageBackground>
          </TouchableOpacity>
          <TouchableOpacity style={homeStyles.cardView} onPress={() => navigation.navigate(ROUTES.PROJECTS)}>
            <ImageBackground source={IMAGES.stars} style={homeStyles.imgBack} >
              <View style={[homeStyles.iconView, { backgroundColor: COLORS.purple }]}>
                <ICONS.AntDesign name='filetext1' size={24} color={COLORS.white} />
              </View>
              <View style={homeStyles.cardTitleView}>
                <View>
                  <Text style={homeStyles.cardTitle}>{STRINGS.projects}</Text>
                  <Text style={homeStyles.subText}>{STRINGS.projectsSubText}</Text>
                </View>
                <View style={homeStyles.arrowView}>
                  <ICONS.AntDesign name='arrowright' size={24} color={COLORS.white} />
                </View>
              </View>
            </ImageBackground>
          </TouchableOpacity>
          <Card onPress={()=>navigation.navigate('Missing')}/>
          <LogOut
            showLogoutModal={showLogoutModal}
            handleCancel={handleCancel}
            handleLogout={handleLogout}
            handleModalPress={handleModalPress}
          />
          </ScrollView>
        </View>
      </View>
    </View>
    </View>
  )
}

