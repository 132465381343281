import { getIdFromStorage } from '../storage/StorgeHelper'
import NetInfo from '@react-native-community/netinfo'
import FlashMessage from '../../utils/flashMessage'
import React from 'react'
import { URLS } from '../../constants'

const WSErrorType = {
  SERVER: 1,
  API: 2,
  NETWORK: 3,
}

const ErrorMsg = {
  msgNoInternet: "Internet is not connected",
  msgConnectionLost: "Connection lost. Please check your network connectivity",
  msgServerError: "Something went wrong, please try again",
  msgRequestTimeOut: "Request time out, please try again",
}

export default class ApiManager extends React.Component {

  static myInstance = null

  static getInstance() {

    if (this.myInstance === null) {
      this.myInstance = new ApiManager()
    }
    return this.myInstance
  }

  getHeader() {

    return {

      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-Metabase-Session': getIdFromStorage()
    }
  }

  getHeaderGETAPI() {

    return {

      'Content-Type': 'application/json',
      'cache-control': 'no-cache',
      'X-Metabase-Session': getIdFromStorage()
    }
  }

  queryString = (obj) => {

    if (Object.keys(obj).length === 0) {
      return ''
    }

    let str = '?' + Object.keys(obj).reduce(function (a, k) {
      a.push(k + '=' + encodeURIComponent(obj[k]))
      return a
    }, []).join('&')
    return str
  }

  getMultiPartHeader() {

    return {
      'Content-Type': 'multipart/form-data',
      "Accept": "*/*",
     
    }
  }

  onGetApi = async (endPoint, request = {},head, methodType = 'GET') => new Promise((resolve, reject) => {

    NetInfo.fetch().then(state => {
      if (state.isConnected != null && state.isConnected != false && state.isConnected != undefined) {

        let queryString = this.queryString(request)
        let baseUrl = URLS.BASE_URL
        let apiUrl = baseUrl + endPoint + queryString
        let header = this.getHeader()
        fetch(apiUrl, {
          method: methodType,
          headers: header
        }).then((response) => {

          response.json().then((json) => {

            resolve(new WSResult(response, json, 1))
            if (json.errors) {
              console.log('ERRORs:----', json.errors)

              return reject(new WSError(ErrorMsg.msgServerError, json.errors.code, WSErrorType.API))
            }
                                

          }).catch((error) => {
            console.log('ERROR:----', error)
            return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API))
          })

        }).catch((error) => {
          console.log("sadgi")
          console.log('ERROR:----', error)
          return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.SERVER))
        })

      } else {
        console.log("sadgi")
        FlashMessage(ErrorMsg.msgNoInternet, '1')
        return reject(new WSError(ErrorMsg.msgNoInternet, 12004, WSErrorType.API))

      }
    })
  })

  onPostApi = async (endPoint, requestData = {}, methodType = 'POST', isMultipart = false) => new Promise((resolve, reject) => {

    NetInfo.fetch().then(state => {
      if (state.isConnected != null && state.isConnected != false && state.isConnected != undefined) {

        var baseUrl = URLS.BASE_URL
        let newHeader = isMultipart ? this.getMultiPartHeader() : this.getHeader()
        let jsonData = isMultipart ? requestData : JSON.stringify(requestData)

        // console.log('HEADER:----', newHeader)
        // console.log('BODYYYYY:----', jsonData)
        // console.log('API URL:------',baseUrl + endPoint)

        fetch(baseUrl + endPoint, {
          method: methodType,
          headers: newHeader,
          body: jsonData,

        }).then((response) => {      
           response.json().then((json) => {
            resolve(new WSResult(response, json, 1))
          }).catch((error) => {
            console.log('ERROR:----', error)
            FlashMessage(ErrorMsg.msgServerError, '1')
            return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API))
          })

        }).catch((error) => {
          console.log('ERROR:----', error)
          FlashMessage(ErrorMsg.msgServerError, '1')
          return reject(new WSError(ErrorMsg.msgServerError, error.code, WSErrorType.API))
        })
      } else {

        FlashMessage(ErrorMsg.msgNoInternet, '1')
        return reject(new WSError(ErrorMsg.msgNoInternet, 12004, WSErrorType.API))
      }
    })
  })

}

class WSError {

  constructor(
    msg = '',
    code = 0,
    type = WSErrorType.API
  ) {
    this.msg = msg
    this.code = code
    this.type = type
  }
}

class WSResult {

  constructor(
    responce = Response,
    json = JSON,
    code = 0,
  ) {
    this.responce = responce
    this.json = json
    this.code = code
  }

}