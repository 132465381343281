import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, Text, TextInput, TouchableOpacity, Animated, Platform, Dimensions, Keyboard } from 'react-native'
import { COLORS, ERRORS_STRINGS, FONTS, STRINGS } from '../../constants'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { setIdToStorage } from '../../services/storage/StorgeHelper'
import { AuthContext } from '../../context/CreateContext'
import { loginApi } from '../../controllers/loginSession'
import { loginStyles } from '../../styles/loginStyles'
import FlashMessage from '../../utils/flashMessage'
import APILoader from '../../components/APILoader'
import { _scale } from '../../utils'

export default function Login({ navigation }) {
  const [showPassword, updatePassword] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [email, setEmail] = useState('')
  const [emailAnim] = useState(new Animated.Value(0))
  const [passwordAnim] = useState(new Animated.Value(0))
  const auth = useContext(AuthContext)
  const emailInputRef = useRef(null)
  const passwordInputRef = useRef(null)
  const { height } = Dimensions.get('screen')
  const { top } = useSafeAreaInsets()

  useEffect(() => {
    if (password.length > 0) {
      handlePasswordFocus()
    }
  }, [password])

  const handleEmailFocus = () => {
    Animated.timing(emailAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: false,
    }).start()
  }

  const handlePasswordFocus = () => {
    Animated.timing(passwordAnim, {
      toValue: 1,
      duration: 300,
      useNativeDriver: false,
    }).start()
  }

  const handleEmailBlur = () => {
    if (email.trim() === '') {
      Animated.timing(emailAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start()
    }
  }

  const handlePasswordBlur = () => {
    if (password.trim() === '') {
      Animated.timing(passwordAnim, {
        toValue: 0,
        duration: 300,
        useNativeDriver: false,
      }).start()
    }
  }

  const renderFloatingLabel = (label, animValue) => {
    const labelStyle = {
      position: 'absolute',
      left: Platform.OS === 'android' ? _scale(48) : _scale(40),
      fontSize: _scale(14),
      fontFamily: FONTS.Futura.book,
      top: animValue.interpolate({
        inputRange: [0, 1.8],
        outputRange: [18, 2],
      }),
      left:animValue.interpolate({
        inputRange: [0, 2],
        outputRange: [18, 2],
      }),
      fontSize: animValue.interpolate({
        inputRange: [0, 1],
        outputRange: [16, 12],
      }),
      color: animValue.interpolate({
        inputRange: [0, 1],
        outputRange: [COLORS.black, COLORS.black],
      }),
    }

    return (
      <Animated.Text style={labelStyle}>
        {label}
      </Animated.Text>
    )
  }

  const onPressLoginBtn = () => {
    if (checkValidation()) {
      Keyboard.dismiss()
      let request = {
        username: email,
        password,
      }
      if (checkValidation()) {
        setIsLoading(true)
        loginApi(request)
          .then((res) => {
            if (res.id) {
              AsyncStorage.setItem('Token', JSON.stringify(res.id))
              setIdToStorage(res.id)
              auth.authenticate(true)
            } else if (res.errors) {
              FlashMessage(ERRORS_STRINGS.userNotExists, '1')
            }
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            setIsLoading(false)
          })
      }
    }
  }

  const checkValidation = () => {
    if (email.trim().length === 0) {
      FlashMessage(ERRORS_STRINGS.emailBlank, '1')
      return false
    }

    if (!email.match(STRINGS.emailRegex)) {
      FlashMessage(ERRORS_STRINGS.invalidEmail, '1')
      return false
    }

    if (password.trim().length === 0) {
      FlashMessage(ERRORS_STRINGS.passwordBlank, '1')
      return false
    }
    return true
  }

  return (
    <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
      {isLoading && <APILoader />}
      <View style={{ backgroundColor: COLORS.light, padding: _scale(20), borderRadius: _scale(10) }}>
      <Text style={{  
        fontFamily: FONTS.Futura.semiBold,
        fontSize: _scale(32),
        textAlign:'center',
        color: COLORS.black}}>Metabase</Text>

        <Text style={loginStyles.title}>Welcome Back,</Text>
        <View>
          {renderFloatingLabel('Email', emailAnim)}
          <TextInput
            ref={emailInputRef}
            style={{
              marginTop: 10,
              borderWidth: 2,
              padding: 10,
              borderRadius: 10,
              width: _scale(300),
              fontFamily: FONTS.Futura.book,
              position: 'relative', 
            }}
            value={email}
            onChangeText={(text) => setEmail(text)}
            onSubmitEditing={() => passwordInputRef.current.focus()}
            autoCapitalize="none"
            autoCorrect={false}
            keyboardType={'email-address'}
            placeholderTextColor={COLORS.grey}
            blurOnSubmit={false}
            returnKeyLabel="next"
            clearButtonMode="always"
            onFocus={handleEmailFocus}
            onBlur={handleEmailBlur}
          />
        </View>
        <View>
        {renderFloatingLabel('Password', passwordAnim)}
        <TextInput
          ref={passwordInputRef}
          style={{
            marginTop: 10,
            borderWidth: 2,
            padding: 10,
            borderRadius: 10,
            width: _scale(300),
            fontFamily: FONTS.Futura.book,
            position: 'relative', // Add this line
          }}
          value={password}
          onChangeText={(text) => setPassword(text)}
          secureTextEntry={!showPassword}
          autoCorrect={false}
          placeholderTextColor={COLORS.grey}
          onFocus={handlePasswordFocus}
          onSubmitEditing={onPressLoginBtn}
          onBlur={handlePasswordBlur}
          returnKeyLabel="go"
          clearButtonMode="always"
        />
        </View>
        <TouchableOpacity
          style={{
            borderWidth: 2,
            marginTop: _scale(30),
            padding: 10,
            borderRadius: 10,
            alignItems: 'center',
            backgroundColor: COLORS.appBlue,
          }}
          onPress={onPressLoginBtn}
        >
          <Text style={{ fontFamily: FONTS.Futura.bold, color: COLORS.white, fontWeight: '600' }}>Login</Text>
        </TouchableOpacity>
      </View>
    </View>
  )
}
