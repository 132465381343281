import { StyleSheet } from "react-native";
import { COLORS, FONTS } from "../constants";
import { _scale } from "../utils";

export const memDetailStyles = StyleSheet.create({
  mainView: {
    flex: 1,
    backgroundColor: COLORS.appBlue,
  },
  secondView: {
    flexDirection: "row",
    marginTop: _scale(12),
  },
  thirdView: {
    flex: 1,
    backgroundColor: COLORS.white,
    borderTopLeftRadius: _scale(20),
    borderTopRightRadius: _scale(20),
  },
  flatlist: {
    flex: 1,
  },
  imgView: {
    height: _scale(90),
    width: _scale(90),
    borderRadius: _scale(12),
    marginLeft: _scale(20),
    borderWidth: 2,
    borderColor: COLORS.lightgrey,
  },
  image: {
    height: "100%",
    width: "100%",
    borderRadius: _scale(10),
  },
  employee: {
    fontSize: 15,
    lineHeight: 20,
    fontFamily: FONTS.Futura.semiBold,
    textDecorationLine: "underline",
  },
  userNameView: {
    marginHorizontal: _scale(10),
    paddingHorizontal: _scale(5),
    flex: 1,
  },
  userName: {
    color: COLORS.white,
    fontFamily: FONTS.Futura.semiBold,
    fontSize: _scale(24),
  },
  innerDesView: {
    flexDirection: "row",
    marginTop: _scale(17),
  },
  description: {
    color: COLORS.black,
    fontFamily: FONTS.Futura.medium,
    flex: 1,
    marginHorizontal: _scale(20),
    marginBottom: _scale(15),
  },
  subUserName: {
    fontSize: _scale(17),
    fontFamily: FONTS.Futura.book,
    color: COLORS.light,
    textDecorationLine: "underline",
  },
  title: {
    color: COLORS.black,
    fontSize: _scale(20),
    margin: _scale(20),
    fontFamily: FONTS.Futura.medium,
  },
  cardView: {
    marginHorizontal: _scale(20),
    marginTop: _scale(20),
    backgroundColor: COLORS.light,
    borderRadius: _scale(12),
  },
  cardInnerView: {
    justifyContent: "space-between",
    padding: _scale(20),
    flexDirection: "row",
  },
  headings: {
    color: COLORS.black,
    fontSize: _scale(16),
    fontFamily: FONTS.Futura.medium,
  },
  btns: {
    marginTop: _scale(5),
  },
  divider: {
    borderBottomWidth: 1,
    borderColor: COLORS.divider,
  },
  content: {
    color: COLORS.black,
    fontSize: _scale(14),
    fontFamily: FONTS.Futura.semiBold,
    flex: 0.8,
  },
  workedHrs: {
    color: COLORS.black,
    fontSize: _scale(14),
    fontFamily: FONTS.Futura.medium,
  },

  imageUser: {
    backgroundColor: COLORS.lightgrey,
    padding: _scale(20),
    height: _scale(92),
    alignItems: "center",
    justifyContent: "center",
    width: _scale(92),
    borderRadius: _scale(12),
    marginLeft: _scale(20),
  },
  innerView: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: _scale(20),
    paddingTop: _scale(20),
  },
  noDataView: {
    alignItems: "center",
    justifyContent: "center",
  },
  noData: {
    fontFamily: FONTS.Futura.semiBold,
    fontSize: _scale(20),
    color: COLORS.black,
    marginTop: _scale(40),
  },
});
