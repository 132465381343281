export const requests = {
    activeUserRequest: {
        "type": "native",
        "native": {
            "query": `SELECT u.id, u.photo, u.email, u.name, u.active,
                        COUNT(DISTINCT DATE(tc.created_at)) AS no_of_status,
                        MAX(DATE(tc.created_at)) AS last_status,
                        efl.value AS technology, 
                        efl_mobile.value as mobile,
                        efl_skype.value as skype
                        FROM users u
                        JOIN tasks_comments tc ON tc.created_by = u.id AND tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY)
                        LEFT JOIN extra_fields_list efl ON efl.bind_id = u.id AND efl.extra_fields_id = 21
                        LEFT JOIN extra_fields_list efl_mobile ON efl_mobile.bind_id = u.id AND efl_mobile.extra_fields_id = 9
                        LEFT JOIN extra_fields_list efl_skype ON efl_skype.bind_id = u.id AND efl_skype.extra_fields_id = 31
                        WHERE u.active = TRUE 
                        AND tc.worked_hours > 0
                        GROUP BY u.id, u.name,  efl.value
                        ORDER BY  efl.value,u.name;`,
        },
        "database": 2,
        "parameters": [],
        "result_format": "json"
    },
    inactiveUserRequest: {
        "type": "native",
        "native": {

            "query": `SELECT u.id, u.photo, u.email, u.name, u.active,
                    COUNT(DISTINCT DATE(tc.created_at)) AS no_of_status,
                    MAX(DATE(tc.created_at)) AS last_status,
                    efl.value AS technology
                    FROM users u
                    JOIN tasks_comments tc ON tc.created_by = u.id 
                    LEFT JOIN extra_fields_list efl ON efl.bind_id = u.id AND efl.extra_fields_id = 21
                    WHERE u.active = FALSE OR u.active IS NULL
                    GROUP BY u.id, u.name, efl.value
                    ORDER BY efl.value, u.name;`,
        },
        "database": 2,
        "parameters": [],
        "result_format": "json"
    },
    allUsersRequest: {
        "type": "native",
        "native": {
            "query": `SELECT u.id, u.photo, u.email, u.name, u.active,
                    COUNT(DISTINCT DATE(tc.created_at)) AS no_of_status,
                    MAX(DATE(tc.created_at)) AS last_status,
                    efl.value AS technology
                    FROM users u
                    JOIN tasks_comments tc ON tc.created_by = u.id 
                    LEFT JOIN extra_fields_list efl ON efl.bind_id = u.id AND efl.extra_fields_id = 21
                    WHERE u.active = FALSE OR u.active IS NULL OR u.active = TRUE
                    GROUP BY u.id, u.name, efl.value
                    ORDER BY efl.value, u.name;`,
        },
        "database": 2,
        "parameters": [],
        "result_format": "json"
    },
    missingStatus:{
        "type": "native",
        "native": {
            "query": `SELECT u.id, u.photo, u.email, u.name, u.active, efl.value AS technology, 
            efl_mobile.value as mobile,
            efl_skype.value as skype,
            MAX(DATE(tc.created_at)) AS last_status
            FROM users u 
            INNER JOIN extra_fields_list efl ON efl.bind_id = u.id AND efl.extra_fields_id = 21
            INNER JOIN extra_fields_list efl_mobile ON efl_mobile.bind_id = u.id AND efl_mobile.extra_fields_id = 9
            INNER JOIN extra_fields_list efl_skype ON efl_skype.bind_id = u.id AND efl_skype.extra_fields_id = 31
            LEFT JOIN tasks_comments tc ON tc.created_by = u.id
            WHERE u.id 
                NOT IN (
                    SELECT users.id as user_id FROM projects
                    LEFT JOIN projects_status ON projects.projects_status_id =  projects_status.id
                    LEFT JOIN tasks ON projects.id = tasks.projects_id
                    LEFT JOIN tasks_comments ON tasks_comments.tasks_id = tasks.id
                    JOIN users ON tasks_comments.created_by = users.id
                    WHERE tasks_comments.worked_hours > 0 
                    AND tasks_comments.in_trash IS NULL 
                    AND tasks.in_trash IS NULL 
                    AND tasks.name Is NOT NULL             
                    AND (
                        date(tasks_comments.created_at) = DATE(DATE_SUB(NOW(), INTERVAL 1 DAY))) OR 
	                    (date(tasks_comments.created_at) = DATE(DATE_SUB(NOW(), INTERVAL 2 DAY)) AND DAYNAME(now()) = 'Sunday')
                    	OR (date(tasks_comments.created_at) = DATE(DATE_SUB(NOW(), INTERVAL 3 DAY)) AND DAYNAME(now()) = 'Monday')
                	)
                AND u.active = TRUE
                AND tc.worked_hours > 0 
               
            GROUP BY u.id, u.name, efl.value
            ORDER BY efl.value ASC, last_status ASC;`,
        },
        "database": 2,
        "parameters": [],
        "result_format": "json"
    },
    projectListRequest: {
        "type": "native",
        "native": {
            "query": `select p.id, p.name, ps.name project_status, pg.name group_name, pt.name project_type, (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id) total_hours_spent,
            (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id and tc.created_at >= DATE_SUB(NOW(), INTERVAL 7 DAY)) total_hours_in_7_days,
            p.start_date,
            (select sum(value) from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id in(25,22,24,23,26,27)) total_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 2) start_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 28) target_end_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 6) end_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 30) warranty_end_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 25) design_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 22) php_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 24) android_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 23) ios_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 26) dotnet_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 27) java_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 32) weekly_allocated_hours
            From projects p, projects_status ps, projects_groups pg, projects_types pt 
            where p.id in (select t.projects_id from tasks_comments tc, tasks t 
                where tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY) 
                and tc.tasks_id = t.id)
            and ps.id= p.projects_status_id 
            and pg.id = p.projects_groups_id 
            and pt.id  = p.projects_types_id 
            order by project_type, total_hours_in_7_days desc `
        },
        "database": 2,
        "parameters": [], "result_format": "json"
    },
    projectTypeCompleted:{
        "type":"native",
        "native" : {
            "query":`select p.id, p.name, ps.name project_status, pg.name group_name, pt.name project_type, (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id) total_hours_spent,
            (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id and tc.created_at >= DATE_SUB(NOW(), INTERVAL 7 DAY)) total_hours_in_7_days,
            p.start_date,
            (select sum(value) from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id in(25,22,24,23,26,27)) total_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 2) start_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 28) target_end_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 6) end_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 30) warranty_end_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 25) design_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 22) php_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 24) android_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 23) ios_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 26) dotnet_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 27) java_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 32) weekly_allocated_hours
            From projects p, projects_status ps, projects_groups pg, projects_types pt 
            where p.id in (select t.projects_id from tasks_comments tc, tasks t 
                where tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY) 
                and tc.tasks_id = t.id)
            and ps.id= p.projects_status_id 
            and pg.id = p.projects_groups_id 
            and pt.id  = p.projects_types_id 
            AND ps.name = 'Completed' 
            order by project_type, total_hours_in_7_days desc `
        },

        "database": 2,
        "parameters": [],
        "result_format": "json"
    },
    
    projectListOngoing:{
      
            "type":"native",
            "native" : {
                "query":`select p.id, p.name, ps.name project_status, pg.name group_name, pt.name project_type, (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id) total_hours_spent,
                (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id and tc.created_at >= DATE_SUB(NOW(), INTERVAL 7 DAY)) total_hours_in_7_days,
                p.start_date,
                (select sum(value) from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id in(25,22,24,23,26,27)) total_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 2) start_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 28) target_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 6) end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 30) warranty_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 25) design_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 22) php_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 24) android_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 23) ios_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 26) dotnet_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 27) java_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 32) weekly_allocated_hours
                From projects p, projects_status ps, projects_groups pg, projects_types pt 
                where p.id in (select t.projects_id from tasks_comments tc, tasks t 
                    where tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY) 
                    and tc.tasks_id = t.id)
                and ps.id= p.projects_status_id 
                and pg.id = p.projects_groups_id 
                and pt.id  = p.projects_types_id 
                 AND ps.name = 'Ongoing'
                order by project_type, total_hours_in_7_days desc `
            },
    
            "database": 2,
            "parameters": [],
            "result_format": "json"
        },
        excessHours:{
            "type": "native",
            "native": {
                "query":  `SELECT
                name,
                project_status,
                project_type,
                id,
                total_hours_spent,
                total_estimate,
                total_hours_in_7_days
            FROM
                project_summary
            WHERE
                total_hours_in_7_days > 0
                AND total_hours_spent >total_estimate * 8
                AND project_type = 'Fixed Bid';
            `,
            },
            "database": 2,
            "parameters": [],
            "result_format": "json"
           
        },
        fixedBid:{
      
            "type":"native",
            "native" : {
                "query":`select p.id, p.name, ps.name project_status, pg.name group_name, pt.name project_type, (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id) total_hours_spent,
                (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id and tc.created_at >= DATE_SUB(NOW(), INTERVAL 7 DAY)) total_hours_in_7_days,
                p.start_date,
                (select sum(value) from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id in(25,22,24,23,26,27)) total_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 2) start_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 28) target_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 6) end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 30) warranty_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 25) design_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 22) php_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 24) android_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 23) ios_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 26) dotnet_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 27) java_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 32) weekly_allocated_hours
                From projects p, projects_status ps, projects_groups pg, projects_types pt 
                where p.id in (select t.projects_id from tasks_comments tc, tasks t 
                    where tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY) 
                    and tc.tasks_id = t.id)
                and ps.id= p.projects_status_id 
                and pg.id = p.projects_groups_id 
                and pt.id  = p.projects_types_id 
                AND pt.name = 'Fixed Bid'
                order by project_type, total_hours_in_7_days desc `
            },
    
            "database": 2,
            "parameters": [],
            "result_format": "json"
        },
        billable:{
      
            "type":"native",
            "native" : {
                "query":`select p.id, p.name, ps.name project_status, pg.name group_name, pt.name project_type, (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id) total_hours_spent,
                (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id and tc.created_at >= DATE_SUB(NOW(), INTERVAL 7 DAY)) total_hours_in_7_days,
                p.start_date,
                (select sum(value) from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id in(25,22,24,23,26,27)) total_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 2) start_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 28) target_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 6) end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 30) warranty_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 25) design_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 22) php_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 24) android_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 23) ios_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 26) dotnet_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 27) java_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 32) weekly_allocated_hours
                From projects p, projects_status ps, projects_groups pg, projects_types pt 
                where p.id in (select t.projects_id from tasks_comments tc, tasks t 
                    where tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY) 
                    and tc.tasks_id = t.id)
                and ps.id= p.projects_status_id 
                and pg.id = p.projects_groups_id 
                and pt.id  = p.projects_types_id 
                 AND pt.name = 'Billable'
                order by project_type, total_hours_in_7_days desc `
            },
    
            "database": 2,
            "parameters": [],
            "result_format": "json"
        },
        billableTM:{
      
            "type":"native",
            "native" : {
                "query":`select p.id, p.name, ps.name project_status, pg.name group_name, pt.name project_type, (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id) total_hours_spent,
                (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id and tc.created_at >= DATE_SUB(NOW(), INTERVAL 7 DAY)) total_hours_in_7_days,
                p.start_date,
                (select sum(value) from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id in(25,22,24,23,26,27)) total_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 2) start_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 28) target_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 6) end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 30) warranty_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 25) design_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 22) php_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 24) android_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 23) ios_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 26) dotnet_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 27) java_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 32) weekly_allocated_hours
                From projects p, projects_status ps, projects_groups pg, projects_types pt 
                where p.id in (select t.projects_id from tasks_comments tc, tasks t 
                    where tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY) 
                    and tc.tasks_id = t.id)
                and ps.id= p.projects_status_id 
                and pg.id = p.projects_groups_id 
                and pt.id  = p.projects_types_id 
                 AND pt.name = 'Billable (T&M)'
                order by project_type, total_hours_in_7_days desc `
            },
    
            "database": 2,
            "parameters": [],
            "result_format": "json"
        },

        internal:{
      
            "type":"native",
            "native" : {
                "query":`select p.id, p.name, ps.name project_status, pg.name group_name, pt.name project_type, (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id) total_hours_spent,
                (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id and tc.created_at >= DATE_SUB(NOW(), INTERVAL 7 DAY)) total_hours_in_7_days,
                p.start_date,
                (select sum(value) from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id in(25,22,24,23,26,27)) total_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 2) start_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 28) target_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 6) end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 30) warranty_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 25) design_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 22) php_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 24) android_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 23) ios_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 26) dotnet_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 27) java_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 32) weekly_allocated_hours
                From projects p, projects_status ps, projects_groups pg, projects_types pt 
                where p.id in (select t.projects_id from tasks_comments tc, tasks t 
                    where tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY) 
                    and tc.tasks_id = t.id)
                and ps.id= p.projects_status_id 
                and pg.id = p.projects_groups_id 
                and pt.id  = p.projects_types_id 
                 AND pt.name = 'Internal'
                order by project_type, total_hours_in_7_days desc `
            },
    
            "database": 2,
            "parameters": [],
            "result_format": "json"
        },
        training:{
      
            "type":"native",
            "native" : {
                "query":`select p.id, p.name, ps.name project_status, pg.name group_name, pt.name project_type, (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id) total_hours_spent,
                (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id and tc.created_at >= DATE_SUB(NOW(), INTERVAL 7 DAY)) total_hours_in_7_days,
                p.start_date,
                (select sum(value) from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id in(25,22,24,23,26,27)) total_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 2) start_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 28) target_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 6) end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 30) warranty_end_date,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 25) design_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 22) php_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 24) android_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 23) ios_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 26) dotnet_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 27) java_estimate,
                (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 32) weekly_allocated_hours
                From projects p, projects_status ps, projects_groups pg, projects_types pt 
                where p.id in (select t.projects_id from tasks_comments tc, tasks t 
                    where tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY) 
                    and tc.tasks_id = t.id)
                and ps.id= p.projects_status_id 
                and pg.id = p.projects_groups_id 
                and pt.id  = p.projects_types_id 
                 AND pt.name = 'R&D / Training'
                order by project_type, total_hours_in_7_days desc `
            },
    
            "database": 2,
            "parameters": [],
            "result_format": "json"
        },
}
export const requestFunctions = (id, activeStatus) => {
    console.log(activeStatus,"ACTIVE STATUS");
    if (activeStatus == true) {
        return {
            "type": "native",
            "native": {
                "query": `SELECT * FROM projects
                        LEFT JOIN projects_status ON projects.projects_status_id =  projects_status.id
                        LEFT JOIN tasks ON projects.id = tasks.projects_id
                        LEFT JOIN tasks_comments ON tasks_comments.tasks_id = tasks.id
                        JOIN users ON tasks_comments.created_by = users.id
                        WHERE tasks_comments.worked_hours > 0 
                        AND tasks_comments.in_trash IS NULL 
                        AND tasks.in_trash IS NULL 
                        AND tasks.name Is NOT NULL             
                        AND users.id = ${id}
                        AND tasks_comments.created_at >= DATE_SUB(NOW(), INTERVAL 30 DAY)
                        ORDER BY tasks_comments.created_at DESC` },

            "database": 2,
            "parameters": [],
            "result_format": "json"
        }
    }
    else if(activeStatus == false) {
        return {
            "type": "native",
            "native": {
                "query": `SELECT * FROM projects
                        LEFT JOIN projects_status ON projects.projects_status_id =  projects_status.id
                        LEFT JOIN tasks ON projects.id = tasks.projects_id
                        LEFT JOIN tasks_comments ON tasks_comments.tasks_id = tasks.id
                        JOIN users ON tasks_comments.created_by = users.id
                        WHERE tasks_comments.worked_hours > 0 
                        AND tasks_comments.in_trash IS NULL 
                        AND tasks.in_trash IS NULL 
                        AND tasks.name Is NOT NULL             
                        AND users.id = ${id}
                        AND tasks_comments.created_at >= DATE_SUB(NOW(), INTERVAL 30 DAY)
                        ORDER BY tasks_comments.created_at DESC` },

            "database": 2,
            "parameters": [],
            "result_format": "json"
        }
    }
    else if(activeStatus == undefined){
        return {
            "type": "native",
            "native": {
                "query": `SELECT * FROM projects
                        LEFT JOIN projects_status ON projects.projects_status_id =  projects_status.id
                        LEFT JOIN tasks ON projects.id = tasks.projects_id
                        LEFT JOIN tasks_comments ON tasks_comments.tasks_id = tasks.id
                        JOIN users ON tasks_comments.created_by = users.id
                        WHERE tasks_comments.worked_hours > 0 
                        AND tasks_comments.in_trash IS NULL 
                        AND tasks.in_trash IS NULL 
                        AND tasks.name Is NOT NULL             
                        AND users.id = ${id}
                        AND tasks_comments.created_at >= DATE_SUB(NOW(), INTERVAL 30 DAY)
                        ORDER BY tasks_comments.created_at DESC` },

            "database": 2,
            "parameters": [],
            "result_format": "json"
        }
    }
}


export const projectDetailRequest = (id) => {
  return  {
        "type": "native",
        "native": {
            "query": `SELECT tasks_comments.*, tasks.name,users.name , tasks.projects_id 
                      FROM projects     
                      LEFT JOIN projects_status ON projects.projects_status_id =  projects_status.id
                      LEFT JOIN tasks ON projects.id = tasks.projects_id
                      LEFT JOIN tasks_comments ON tasks_comments.tasks_id = tasks.id
                      JOIN users ON tasks_comments.created_by = users.id
                      WHERE tasks_comments.worked_hours > 0 
                      AND tasks_comments.in_trash IS NULL 
                      AND tasks.in_trash IS NULL    
                      AND tasks.projects_id = ${id}
                      AND tasks_comments.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY)
                      ORDER BY tasks_comments.created_at DESC`,
        },
        "database": 2,
        "parameters": [],
        "result_format": "json"
    }
}
export const projectDetailWithID = (id) => {
    return {
        "type":"native",
        "native" : {
            "query":`select p.id, p.name, ps.name project_status, pg.name group_name, pt.name project_type, (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id) total_hours_spent,
            (select round(sum(worked_hours)) from tasks_comments tc , tasks t where t.projects_id = p.id and tc.tasks_id = t.id and tc.created_at >= DATE_SUB(NOW(), INTERVAL 7 DAY)) total_hours_in_7_days,
            p.start_date,
            (select sum(value) from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id in(25,22,24,23,26,27)) total_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 2) start_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 28) target_end_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 6) end_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 30) warranty_end_date,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 25) design_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 22) php_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 24) android_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 23) ios_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 26) dotnet_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 27) java_estimate,
            (select value from extra_fields_list efl where efl.bind_id = p.id and efl.extra_fields_id = 32) weekly_allocated_hours
            From projects p, projects_status ps, projects_groups pg, projects_types pt 
            where p.id in (select t.projects_id from tasks_comments tc, tasks t 
                where tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY) 
                and tc.tasks_id = t.id)
            and ps.id= p.projects_status_id 
            and pg.id = p.projects_groups_id 
            and pt.id  = p.projects_types_id 
             AND p.id = ${id}
            order by project_type, total_hours_in_7_days desc `
        },
    
        "database": 2,
        "parameters": [],
        "result_format": "json"
       }
       
}
export const teamDetailWithID = (id) => {
    return  {
        "type": "native",
        "native": {
            "query": `SELECT u.id, u.photo, u.email, u.name, u.active,
            COUNT(DISTINCT DATE(tc.created_at)) AS no_of_status,
            MAX(DATE(tc.created_at)) AS last_status,
            efl.value AS technology, 
            efl_mobile.value as mobile,
            efl_skype.value as skype
            FROM users u
            JOIN tasks_comments tc ON tc.created_by = u.id AND tc.created_at >= DATE_SUB(NOW(), INTERVAL 60 DAY)
            LEFT JOIN extra_fields_list efl ON efl.bind_id = u.id AND efl.extra_fields_id = 21
            LEFT JOIN extra_fields_list efl_mobile ON efl_mobile.bind_id = u.id AND efl_mobile.extra_fields_id = 9
            LEFT JOIN extra_fields_list efl_skype ON efl_skype.bind_id = u.id AND efl_skype.extra_fields_id = 31
            WHERE u.id = ${id}
            GROUP BY u.id, u.name,  efl.value
            ORDER BY  efl.value,u.name`,
                   
        },
        "database": 2,
        "parameters": [],
        "result_format": "json"
    }
} 