import { StyleSheet } from "react-native";
import { _scale } from "../utils";
import { COLORS, FONTS } from "../constants";


export const projectListCellStyle = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderColor:COLORS.divider,
        marginHorizontal: 20,
        flex: 1,
    },
    avatarView: {
        marginTop: _scale(10),
        height: _scale(48),
        width: _scale(48),
        borderRadius: _scale(10),
    },
    nameView: {
        padding: 5,
        flex: 1
    },
    names: {
        fontFamily: FONTS.Futura.medium,
        fontSize: _scale(16),
        color: COLORS.black,
        marginVertical: 20,
        marginHorizontal: 10,

    },
    innerView: {
        marginVertical: _scale(10),
        marginHorizontal: _scale(20),
        paddingBottom:_scale(20),
        borderRadius: _scale(12),
        backgroundColor: COLORS.white,
    },
    titleView: {
        justifyContent: 'space-between',
        flexDirection: 'row',
        margin: _scale(20),
    },
    title: {
        fontFamily: FONTS.Futura.semiBold,
        fontSize: _scale(20),
        color: COLORS.black,
    },
    statusView: {
        borderRadius: _scale(5),
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: _scale(10),
        height: _scale(32)
    },
    status: {
        fontSize: _scale(14),
        fontFamily: FONTS.Futura.semiBold,
        color: COLORS.darkOrange
    },
    divider: {
        borderBottomWidth: 1,
        borderColor: COLORS.light,
    },
    totaltimeSpentView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginHorizontal: _scale(20),
        paddingTop: _scale(15)
    },
    projectTypes: {
        color: COLORS.grey,
        fontSize: _scale(14),
        fontFamily: FONTS.Futura.medium,
    },
    totaltime: {
        color: COLORS.grey,
        fontSize: _scale(14),
        fontFamily: FONTS.Futura.medium,
        marginLeft: _scale(10)

    },
    totalEstimate: {
        color: COLORS.black,
        fontSize: _scale(14),
        fontFamily: FONTS.Futura.semiBold,

    },
    secondView: {
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        marginBottom: _scale(20)
    },
    greenView: {
        borderWidth: 2,
        borderColor: COLORS.white,
        width: _scale(28),
        height: _scale(5),
        paddingHorizontal: _scale(5),
        paddingVertical: _scale(3),
        elevation:4,
        shadowColor:COLORS.grey,
        shadowOffset: {
            width: 0,
            height: 2,
          },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        alignSelf: 'center'
    },
    progressView:{
        marginTop: _scale(20), 
        marginBottom: _scale(20),
        marginHorizontal: _scale(19),
        flex: 1,
    },
    barStyle: {
        flex: 1,
        height: _scale(24),
        borderRadius: _scale(4),
        backgroundColor: COLORS.parrotGreen,
    }

})