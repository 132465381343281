import { View, ImageBackground, Modal, StyleSheet, ActivityIndicator } from 'react-native'
import { _scale } from '../utils';
import { COLORS } from '../constants';

export default function APILoader(props) {
    return (
        <View>
            <Modal
                animationType='fade'
                visible={true}
                transparent={true}>
                <ImageBackground blurRadius={90} style={styles.modalView}>
                    <View style={styles.gifContainer}>
                        <ActivityIndicator size={'large'} color={COLORS.appBlue} />
                    </View>
                </ImageBackground>
            </Modal>
        </View>
    )
}
const styles = StyleSheet.create({
    modalView: {
        flex: 1,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.transparentDark,
        padding: _scale(35),
    },
    gifContainer: {
        height: _scale(65),
        width: _scale(65),
        borderRadius: _scale(8),
        backgroundColor: 'white',
        justifyContent: 'center',
        alignItems: 'center',
    },

});
