export default {
    LOGIN:'Login',
    HOME:'Home',
    TEAMS:'Teams',
    PROJECTS:'Projects',
    EMPDETAILS:'EmployeeDetails',
    PROJECTDETAILS:'ProjectDetails',
    TASKASSIGN:'TaskAssign',
    TASKASSIGNDETAILS:'TaskAssignDetails',
    PROJECTSUMMARY:'ProjectSummary'

}