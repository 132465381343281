export default class ProjectListRes {
    constructor() { }
  
    initWithRes(resArr) {
      let projectList = [];
      resArr.forEach(element => {
        projectList.push(new ProjectRes().initWithResObj(element));
      });
      return projectList;
    }
  }
  class ProjectRes {
    constructor(android_estimate, design_estimate, dotnet_estimate, end_date, group_name, id, ios_estimate, java_estimate, name, php_estimate, project_status, project_type, start_date, start_date_2, target_end_date, total_estimate, total_hours_in_7_days, total_hours_spent, warranty_end_date,weekly_allocated_hours) {
        this.android_estimate = android_estimate;
        this.design_estimate = design_estimate;
        this.dotnet_estimate = dotnet_estimate;
        this.end_date = end_date;
        this.group_name = group_name;
        this.id = id;
        this.ios_estimate = ios_estimate;
        this.java_estimate = java_estimate;
        this.name = name;
        this.php_estimate = php_estimate;
        this.project_status = project_status;
        this.project_type = project_type;
        this.start_date = start_date;
        this.start_date_2 = start_date_2;
        this.target_end_date = target_end_date;
        this.total_estimate = total_estimate;
        this.total_hours_in_7_days = total_hours_in_7_days;
        this.total_hours_spent = total_hours_spent;
        this.warranty_end_date = warranty_end_date;
        this.weekly_allocated_hours=weekly_allocated_hours;
      }
    
      initWithResObj(response) {
        this.android_estimate = response['android_estimate'] || '';
        this.design_estimate = response['design_estimate'] || '';
        this.dotnet_estimate = response['dotnet_estimate'] || '';
        this.end_date = response['end_date'] || '';
        this.group_name = response['group_name'] || '';
        this.id = response['id'] || '';
        this.ios_estimate = response['ios_estimate'] || '';
        this.java_estimate = response['java_estimate'] || '';
        this.name = response['name'] || '';
        this.php_estimate = response['php_estimate'] || '';
        this.project_status = response['project_status'] || '';
        this.project_type = response['project_type'] || '';
        this.start_date = response['start_date'] || '';
        this.start_date_2 = response['start_date_2'] || '';
        this.target_end_date = response['target_end_date'] || '';
        this.total_estimate = response['total_estimate'] || '';
        this.total_hours_in_7_days = response['total_hours_in_7_days'] || '';
        this.total_hours_spent = response['total_hours_spent'] || '';
        this.warranty_end_date = response['warranty_end_date'] || '';
        this.weekly_allocated_hours=response['weekly_allocated_hours']||''
        return this;
      }
    }