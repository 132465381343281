import { Dimensions, TouchableOpacity, FlatList, View, TextInput, Image, Text, Platform } from 'react-native'
import { getProjectNameApi } from '../../../controllers/getProjectNameController'
import { getExcessActiveHours,getFixedBid,getBillable,getBillableTM,getInternal,getTraining } from '../../../controllers/getExcessActiveHours'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { COLORS, ICONS, ROUTES, STRINGS } from '../../../constants'
import React, { useContext, useEffect, useState } from 'react'
import { projectStyles } from '../../../styles/projectListStyles'
import ProjectList from '../../../components/FlatListProjects'
import { AuthContext } from '../../../context/CreateContext'
import BackHeader from '../../../components/BackHeader'
import { requests } from '../../../utils/requestParams'
import APILoader from '../../../components/APILoader'
import { appStyles } from '../../../styles/appStyles'
import Filter from '../../../components/Filter'
import { _scale, } from '../../../utils'
import IMAGES from '../../../assets'
import AsyncStorage from '@react-native-async-storage/async-storage'

export default function Projects({ navigation }) {
    const [modalVisible, setModalVisible] = useState(false)
    const [shouldShow, setshouldShow] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [data, setData] = useState([]) 
    const {  width } = Dimensions.get('screen')
    const {top } = useSafeAreaInsets()

    const authCtx= useContext(AuthContext)
    useEffect(() => {
        setIsLoading(true)
        getProjectNameApi(requests.projectListRequest).then((res) => {
            const data = res
            const updated =  data.map(item => ({
                ...item,
                total_estimate: item.total_estimate * 8}))
                setData(updated)
            console.log(data);
        }).catch((err) => {
            FlashMessage('Session Expired',1)
            authCtx.authenticate(false)
            AsyncStorage.removeItem('Token')
            setIdToStorage('')
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])
    const handleModalPress = () => {
        setModalVisible(false)
    }
    const allMembFilter = () => {
        setIsLoading(true)
        setModalVisible(false)
        getProjectNameApi(requests.projectListRequest).then((res) => {
            const data = res
            const updated =  data.map(item => ({
                ...item,
                total_estimate: item.total_estimate * 8}))
                setData(updated)
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false)
        })
       
    }
    const ongoing = () => {
        setIsLoading(true)
        setModalVisible(false)
        getProjectNameApi(requests.projectListOngoing).then((res) => { 
            const data = res
            const updated =  data.map(item => ({
                ...item,
                total_estimate: item.total_estimate * 8}))
                setData(updated)
            console.log(data);
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false)
        })
       
       
    }
    const completed = () => {
        setIsLoading(true)
        setModalVisible(false)
        getProjectNameApi(requests.projectTypeCompleted).then((res) => {
            const data = res
            const updated =  data.map(item => ({
                ...item,
                total_estimate: item.total_estimate * 8}))
                setData(updated)
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false)
        })
       
    }
    const fixedBidProjects = () =>{
        console.log('Handling')
        setIsLoading(true)
        setModalVisible(false)
        getFixedBid(requests.fixedBid).then((res) => {
           const data = res
           console.log(data,'EXCESSSSSS');
           const updated =  data.map(item => ({
               ...item,
               total_estimate: item.total_estimate * 8}))
               setData(updated)
       }).catch((err) => {

       }).finally(() => {
           setIsLoading(false)
       })
   }
   const billableProjects = () =>{
    console.log('Handling')
    setIsLoading(true)
    setModalVisible(false)
    getBillable(requests.billable).then((res) => {
       const data = res
       console.log(data,'EXCESSSSSS');
       const updated =  data.map(item => ({
           ...item,
           total_estimate: item.total_estimate * 8}))
           setData(updated)
   }).catch((err) => {

   }).finally(() => {
       setIsLoading(false)
   })
}
const billableProjectsTM = () =>{
    console.log('Handling')
    setIsLoading(true)
    setModalVisible(false)
    getBillableTM(requests.billableTM).then((res) => {
       const data = res
       console.log(data,'EXCESSSSSS');
       const updated =  data.map(item => ({
           ...item,
           total_estimate: item.total_estimate * 8}))
           setData(updated)
   }).catch((err) => {

   }).finally(() => {
       setIsLoading(false)
   })
}
const internalProjects = () =>{
    console.log('Handling')
    setIsLoading(true)
    setModalVisible(false)
    getInternal(requests.internal).then((res) => {
       const data = res
       console.log(data,'EXCESSSSSS');
       const updated =  data.map(item => ({
           ...item,
           total_estimate: item.total_estimate * 8}))
           setData(updated)
   }).catch((err) => {

   }).finally(() => {
       setIsLoading(false)
   })
}
const trainingProjects = () =>{
         console.log('Handling')
         setIsLoading(true)
         setModalVisible(false)
         getTraining(requests.training).then((res) => {
            const data = res
            console.log(data,'EXCESSSSSS');
            const updated =  data.map(item => ({
                ...item,
                total_estimate: item.total_estimate * 8}))
                setData(updated)
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false)
        })
    }
    const handleExcessHours = () =>{
         console.log('Handling')
         setIsLoading(true)
         setModalVisible(false)
         getExcessActiveHours(requests.excessHours).then((res) => {
            const data = res
            console.log(data,'EXCESSSSSS');
            const updated =  data.map(item => ({
                ...item,
                total_estimate: item.total_estimate * 8}))
                setData(updated)
        }).catch((err) => {

        }).finally(() => {
            setIsLoading(false)
        })
    }

    const renderItem = ({ item,index }) => {
            if (searchQuery.length === 0) {
                return (
                    <ProjectList 
                    startDate={item.project_type == 'Billable (T&M)'||item.project_type=='Billable'? false : true}
                    progressBar={item.project_type == 'Billable (T&M)'||item.project_type=='Billable'?false :true}
                    alloted={item.project_type == 'Billable (T&M)'||item.project_type=='Billable'?false :true}
                    seven
                    spent={item.project_type == 'Billable (T&M)'||item.project_type=='Billable'?false :true}
                    index={index}
                    unfilledColor={item.total_hours_spent <= item.total_estimate ? COLORS.lightgrey : item.total_hours_spent == item.total_estimate ? COLORS.parrotGreen : COLORS.tomatoRed}
                    progress={item.total_hours_spent <= item.total_estimate ? item.total_hours_spent / item.total_estimate : (1 - ((item.total_hours_spent - item.total_estimate) / item.total_estimate))}
                    onPressProjectName={() => navigation.navigate(ROUTES.PROJECTSUMMARY, { id: item.id,name:item.name })}
                    barWidth={'100%'}
                    data={item}
                    spentColor={item.total_hours_spent <= item.total_estimate ? COLORS.parrotGreen : COLORS.tomatoRed}
                    statusViewColor={item.project_status == STRINGS.open ? COLORS.lightOrange : COLORS.lightBlue}
                    statusColor={item.project_status == STRINGS.open ? COLORS.darkOrange : COLORS.darkBlue}
                    />
               )
            }
        if (item.name.toUpperCase().includes(searchQuery.toUpperCase())) {
            return (
                    <ProjectList
                    startDate={item.project_type == 'Billable (T&M)'||item.project_type=='Billable'? false :true}
                    progressBar={item.project_type == 'Billable (T&M)'||item.project_type=='Billable'?false :true}
                    alloted={item.project_type == 'Billable (T&M)'||item.project_type=='Billable'?false :true}
                    seven
                    spent={item.project_type == 'Billable (T&M)'||item.project_type=='Billable'?false :true}
                    index={index}
                    unfilledColor={item.total_hours_spent <= item.total_estimate ? COLORS.lightgrey : item.total_hours_spent == item.total_estimate ? COLORS.parrotGreen : COLORS.tomatoRed}
                    progress={item.total_hours_spent <= item.total_estimate ? item.total_hours_spent / item.total_estimate : (1 - ((item.total_hours_spent - item.total_estimate) / item.total_estimate))}
                    onPressProjectName={() => navigation.navigate(ROUTES.PROJECTSUMMARY, { id: item.id,name:item.name })}
                    barWidth={'100%'}
                    data={item}
                    spentColor={item.total_hours_spent <= item.total_estimate ? COLORS.parrotGreen : COLORS.tomatoRed}
                    statusViewColor={item.project_status == STRINGS.open ? COLORS.lightOrange : COLORS.lightBlue}
                    statusColor={item.project_status == STRINGS.open ? COLORS.darkOrange : COLORS.darkBlue}
                    /> 
            )
        }
        
    }

   
    return (
        <View style={projectStyles.container}>
            {isLoading && <APILoader />}
            <View style={{flex:1,paddingTop:top}}>
            <BackHeader title={STRINGS.projects} navigation={navigation} />
            <View style={projectStyles.innerContainer}>
                <View style={projectStyles.innerView}>
                    <View style={projectStyles.inputView}>
                        <ICONS.AntDesign name='search1' size={18} color={COLORS.grey} style={{ marginLeft: 10 }} />
                        <TextInput
                            placeholder={STRINGS.search}
                            style={projectStyles.textInput}
                            placeholderTextColor={COLORS.transparentGrey}
                            onChangeText={(text) => {
                                setSearchQuery(text)
                                if (text.trim().length > 0) {
                                    setshouldShow(true)
                                }
                                else if (text.trim().length == 0) {
                                    setshouldShow(false)
                                }
                            }}
                            value={searchQuery}
                        />
                        {shouldShow &&
                            <TouchableOpacity style={[projectStyles.crossBtn,{borderRadius:1000}]}>
                              <ICONS.Ionicons name='close' size={14} color={COLORS.white} onPress={() => {setSearchQuery(''); setshouldShow(false) }} />
                            </TouchableOpacity>}
                    </View>
                    <TouchableOpacity style={projectStyles.iconView} onPress={() => { setModalVisible(true) }} >
                        <ICONS.AntDesign name='filter' size={18} color={COLORS.transparentGrey} style={{ }} />
                    </TouchableOpacity>

                </View>
            {isLoading == false && data.length == 0 &&  
                <View style={appStyles.noDataView}>
                    <Text style={appStyles.noDataFound}> No data found </Text>
                </View>}
            
                <Filter
                    modal={modalVisible} 
                    secondFilter={'Ongoing'} 
                    allMemb={allMembFilter}
                    activeMemb={ongoing}
                    inActiveMemb={completed}
                    thirdFilter={'Completed'}
                    fixbid={'Fixed Bid'}
                    bill={'Billable'}
                    billTM={'Billable (T&M)'}
                    train={'R&D/Training'}
                    intern={'Internal'}
                    excess={'Excess Hours'}
                    handleModalPress={handleModalPress}
                    excessHours={handleExcessHours}
                    fixedBid={fixedBidProjects}
                    billable={billableProjects}
                    billableTM={billableProjectsTM}
                    training={trainingProjects}
                    internal={internalProjects}
                />
                <FlatList
                    data={data}
                    style={{flex:1}}
                    renderItem={renderItem}
                    ListEmptyComponent={!isLoading && <Footer/>}
                />
                
            </View>
            </View>
        </View>
    )
}
function Footer(){
  return(
        <View style={appStyles.noDataView}>
            <Text style={appStyles.noDataFound}> No data found </Text>
        </View>    
        )
}