import { View, Text, TouchableOpacity } from 'react-native'
import { _scale, } from '../utils'
import { COLORS } from '../constants'
import * as Progress from 'react-native-progress';
import { projectListCellStyle } from '../styles/projectListCellStyle';
import moment from 'moment';
export default function ProjectList(props) {
    return (
        <TouchableOpacity style={projectListCellStyle.innerView} onPress={props.onPressProjectName} activeOpacity={1}>
            <View style={projectListCellStyle.titleView}>
                <View style={{flex: 0.9}}>
                    <Text style={projectListCellStyle.title}>{props.data.name}</Text>
                </View>
                <View style={[projectListCellStyle.statusView, { backgroundColor: props.statusViewColor }]}>
                    <Text style={[projectListCellStyle.status, { color: props.statusColor }]}>{props.data.project_status}</Text>
                </View>
            </View>
            <View style={projectListCellStyle.divider} />
            <View style={projectListCellStyle.titleView}>
                <Text style={projectListCellStyle.projectTypes}>Project Type</Text>
                <Text style={[projectListCellStyle.totaltime, { color: COLORS.black, }]}>{props.data.project_type}</Text>
            </View>
            {props.data.group_name.length > 0 && <View style={{ justifyContent: 'space-between', flexDirection: 'row', marginHorizontal: 20, marginBottom: 20 }}>
                <Text style={projectListCellStyle.projectTypes}>Group Name</Text>
                <Text style={[projectListCellStyle.totaltime, { color: COLORS.black, }]}>{props.data.group_name}</Text>
            </View>}
      
            {props.startDate == true && props.startDate.length>0 &&
            <View style={{ justifyContent: 'space-between', flexDirection: 'row', marginHorizontal: 20 }}>
                <Text style={projectListCellStyle.projectTypes}>Start Date</Text>
                <Text style={[projectListCellStyle.totaltime, { color: COLORS.black, }]}>{String(props.data.start_date).length!==0 ? moment(String(props.data.start_date)).format("DD-MM-YYYY"): ''}</Text>
            </View>
            }
            {props.endDate == true && props.endDate.length>0 &&
            <View style={{ justifyContent: 'space-between', flexDirection: 'row', marginHorizontal: 20 }}>
                <Text style={projectListCellStyle.projectTypes}>End Date</Text>
                <Text style={[projectListCellStyle.totaltime, { color: COLORS.black, }]}>{String(props.data.start_date).length!==0 ? moment(String(props.data.start_date)).format("DD-MM-YYYY"): ''}</Text>
            </View>
            }
            {props.data.weekly_allocated_hours.length > 0 && <View style={projectListCellStyle.totaltimeSpentView}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={[projectListCellStyle.greenView, { backgroundColor: COLORS.skyBlue, shadowColor: COLORS.grey }]} />
                        <Text style={projectListCellStyle.totaltime}>Weekly allocated hours</Text>
                    </View>
                    <Text style={projectListCellStyle.totalEstimate}>{props.data.weekly_allocated_hours} {props.data.weekly_allocated_hours>0?'hrs':'hr'}</Text>
                </View>}
               {props.alloted && <View style={projectListCellStyle.totaltimeSpentView}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={[projectListCellStyle.greenView, { backgroundColor: COLORS.lightgrey, shadowColor: COLORS.grey }]} />
                        <Text style={projectListCellStyle.totaltime}>Total time allotted</Text>
                    </View>
                    <Text style={projectListCellStyle.totalEstimate}>{props.data.total_estimate} {props.data.total_estimate>1?'hrs':'hr'}</Text>
                </View>}
                {props.spent && <View style={projectListCellStyle.totaltimeSpentView}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={[projectListCellStyle.greenView, { backgroundColor: props.spentColor, shadowColor: COLORS.spentColor }]} />
                        <Text style={projectListCellStyle.totaltime}>Total time spent</Text>
                    </View>
                    <Text style={projectListCellStyle.totalEstimate}>{props.data.total_hours_spent} {props.data.total_hours_spent>1?'hrs':'hr'}</Text>
                </View>}
                {props.seven && <View style={projectListCellStyle.totaltimeSpentView}>
                    <View style={{ flexDirection: 'row' }}>
                        <View style={[projectListCellStyle.greenView, { backgroundColor: COLORS.orange, shadowColor: COLORS.grey }]} />
                        <Text style={projectListCellStyle.totaltime}>Total time spent (7 days)</Text>
                    </View>
                    <Text style={projectListCellStyle.totalEstimate}>{props.data.total_hours_in_7_days==null||props.data.total_hours_in_7_days==undefined||String(props.data.total_hours_in_7_days).length == 0 ?'0':props.data.total_hours_in_7_days} {props.data.total_hours_in_7_days?'hrs':'hr'}</Text>
                </View>}

            
          {props.progressBar &&  <View style={projectListCellStyle.progressView}>
           <Progress.Bar
                progress={props.progress}
                width={250}
                style={{flex:1}}
                height={_scale(20)}
                color={COLORS.parrotGreen}
                // showsText={true}
                unfilledColor={props.unfilledColor}
                borderColor={COLORS.lightgrey}

                />
            </View>}
            
        </TouchableOpacity>
    )
}
