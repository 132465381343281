import { TouchableOpacity, FlatList, Text,View, Image, TextInput, StatusBar, SafeAreaView, Platform } from 'react-native'
import { getTeamList } from '../../../controllers/postTeamList'
import { COLORS, FONTS, ICONS, ROUTES, STRINGS } from '../../../constants'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import { requests } from '../../../utils/requestParams'
import TeamList from '../../../components/FlatListTeam'
import { teamStyles } from '../../../styles/teamStyles'
import BackHeader from '../../../components/BackHeader'
import { appStyles } from '../../../styles/appStyles'
import APILoader from '../../../components/APILoader'
import Filter from '../../../components/Filter'
import { useContext, useEffect, useState } from 'react'
import { _scale } from '../../../utils'
import IMAGES from '../../../assets'
import FlashMessage from '../../../utils/flashMessage'
import { AuthContext } from '../../../context/CreateContext'

export default function Teams({ navigation }) {
    const auth = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const [searchQuery, setSearchQuery] = useState('')
    const [shouldShow, setshouldShow] = useState(false)
    const [modalVisible, setModalVisible] = useState(false)
    const [data, setData] = useState([])

    useEffect(() => {
        setIsLoading(true)
        getTeamList(requests.activeUserRequest).then((res) => {
            setData(res)
        }).catch((err) => {
            console.log("Error Teams",err)
            FlashMessage('Session Expired','1')
            auth.authenticate(false)
        }).finally(() => {
            setIsLoading(false)
        })
    }, [])

    const allMembFilter = () => {
        setModalVisible(false)
        setIsLoading(true)
        getTeamList(requests.allUsersRequest).then(res => {
            setData(res)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }
    const activeFilter = () => {
        setModalVisible(false)
        setIsLoading(true)
        getTeamList(requests.activeUserRequest).then(res => {
            setData(res)
        }).catch((err) => {
            console.log(err)
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const inactiveFilter = () => {
        setIsLoading(true)
        setModalVisible(false)
        getTeamList(requests.inactiveUserRequest).then(res => {
            setData(res)
        }).catch((err) => {
            console.log(err);
        }).finally(() => {
            setIsLoading(false)
        })
    }

    const handleModalPress = () => {
        setModalVisible(false)
    }
    const onChangeText = (text) => {
        setSearchQuery(text)
        if (text.trim().length > 0) {
            setshouldShow(true)
        }
        else if (text.trim().length == 0) {
            setshouldShow(false)
        }
    }
    const {top} = useSafeAreaInsets()
    const renderItem = (itemData) => {
        if (searchQuery === "") {
            return (<TeamList show data={itemData.item} container={{marginHorizontal:_scale(20)}} onPressEmpName={() => navigation.navigate(ROUTES.EMPDETAILS, { id: itemData.item.id ,routeName:'Team',item:itemData.item})} />)
        }
        if (itemData.item.name.toUpperCase().includes(searchQuery.toUpperCase())) {
            return (<TeamList show data={itemData.item} container={{marginHorizontal:_scale(20)}} onPressEmpName={() => navigation.navigate(ROUTES.EMPDETAILS, { id: itemData.item.id ,routeName:'Team',item:itemData.item})} />)
        }
    }
    return (
        <View style={Platform.OS=='web'?appStyles.safeAreaMainView:appStyles.safeAreaMainView}>
         <StatusBar barStyle={'light-content'} translucent={Platform.OS=='ios'}/>
            <View style={[teamStyles.container,{paddingTop:top}]}>
                {isLoading && <APILoader />}
                <BackHeader title={STRINGS.team} navigation={navigation} />
                <View style={teamStyles.subContainer}>
                    <View style={teamStyles.innerView}>
                        <View style={teamStyles.inputView}>
                            <ICONS.AntDesign name='search1' size={18} color={COLORS.transparentGrey} style={{ marginLeft: 10 }} />
                            <TextInput
                                placeholder={STRINGS.search}
                                placeholderTextColor={COLORS.transparentGrey}
                                style={teamStyles.textInput}
                                onChangeText={(text) => onChangeText(text)}
                                value={searchQuery}
                            />
                            {shouldShow &&
                            <TouchableOpacity style={teamStyles.crossBtn} >
                              <ICONS.Ionicons name='close' size={14} color={COLORS.white}  onPress={() => { setSearchQuery(''); setshouldShow(false) }} />
                             </TouchableOpacity>
                            }
                        </View>
                        <TouchableOpacity style={teamStyles.iconView} onPress={() => setModalVisible(true)} >
                            <ICONS.AntDesign name='filter' size={18} color={COLORS.transparentGrey} style={{ }} />
                        </TouchableOpacity>
                    </View>
                    {isLoading==false && data.length==0 &&  
                    <View style={teamStyles.noDataView}>
                        <Text style={teamStyles.noData}> No data found </Text>
                    </View>}
                   
                    <Filter
                        modal={modalVisible}
                        secondFilter={STRINGS.active}
                        thirdFilter={STRINGS.inactive}
                        allMemb={allMembFilter}
                        activeMemb={activeFilter}
                        inActiveMemb={inactiveFilter}
                        handleModalPress={handleModalPress}
                    />
                  
                    <FlatList
                        data={data}
                        style={{ flex: 1 }}
                        renderItem={renderItem}
                    />
                </View>
            </View>
        </View>
    )
}

