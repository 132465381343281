export default class ProjectDetailRes {
    constructor() { }
  
    initWithRes(resArr) {
      let projectDetail = [];
      resArr.forEach(element => {
        projectDetail.push(new ProjectRes().initWithResObj(element));
      });
      return projectDetail;
    }
  }
  class ProjectRes {
    constructor(created_at, created_by, description, due_date, id, in_trash, in_trash_date, name, name_2, progress, projects_id, tasks_id, tasks_labels_id, tasks_priority_id, tasks_status_id, tasks_types_id, togo_hours, worked_hours) {
        this.created_at = created_at;
        this.created_by = created_by;
        this.description = description;
        this.due_date = due_date;
        this.id = id;
        this.in_trash = in_trash;
        this.in_trash_date = in_trash_date;
        this.name = name;
        this.name_2 = name_2;
        this.progress = progress;
        this.projects_id = projects_id;
        this.tasks_id = tasks_id;
        this.tasks_labels_id = tasks_labels_id;
        this.tasks_priority_id = tasks_priority_id;
        this.tasks_status_id = tasks_status_id;
        this.tasks_types_id = tasks_types_id;
        this.togo_hours = togo_hours;
        this.worked_hours = worked_hours;
      }
    
      initWithResObj(response) {
        this.created_at = response['created_at'] || '';
        this.created_by = response['created_by'] || '';
        this.description = response['description'] || '';
        this.due_date = response['due_date'] || '';
        this.id = response['id'] || '';
        this.in_trash = response['in_trash'] || '';
        this.in_trash_date = response['in_trash_date'] || '';
        this.name = response['name'] || '';
        this.name_2 = response['name_2'] || '';
        this.progress = response['progress'] || '';
        this.projects_id = response['projects_id'] || '';
        this.tasks_id = response['tasks_id'] || '';
        this.tasks_labels_id = response['tasks_labels_id'] || '';
        this.tasks_priority_id = response['tasks_priority_id'] || '';
        this.tasks_status_id = response['tasks_status_id'] || '';
        this.tasks_types_id = response['tasks_types_id'] || '';
        this.togo_hours = response['togo_hours'] || '';
        this.worked_hours = response['worked_hours'] || '';
        return this;
      }
    }