import { StyleSheet } from "react-native";
import { COLORS, FONTS } from "../constants";
import { _scale } from "../utils";

export const memCellStyles = StyleSheet.create({
    cardView: {
        marginHorizontal: _scale(20),
        backgroundColor: COLORS.light,
        borderRadius: _scale(12),
        marginBottom:_scale(20)
    },
    cardInnerView: {
        justifyContent: 'space-between',
        padding: _scale(20),
        flexDirection: 'row',
    },
    header:{
        color: COLORS.black,
        fontFamily: FONTS.Futura.semiBold, 
        fontSize: _scale(19)
    },
    headings: {
        color: COLORS.black,
        fontSize: _scale(16),
        fontFamily: FONTS.Futura.medium,
    },
    divider: {
        borderBottomWidth: 1,
        borderColor: COLORS.divider,
    },
    innerView: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingHorizontal: _scale(20),
        paddingTop: _scale(20)
    },
    content: {
        color: COLORS.black,
        fontSize: _scale(14),
        fontFamily: FONTS.Futura.semiBold,
        flex: 0.8,    
    },
    workedHrs: {
        color: COLORS.black,
        fontSize: _scale(14),
        fontFamily: FONTS.Futura.medium
    },
    innerDesView: {
        flexDirection: 'row',
        marginTop: _scale(17)
    },
    description: {
        fontFamily: FONTS.Futura.medium,
        marginHorizontal: _scale(20),
        marginBottom: _scale(15),
        color: COLORS.black,
        flex: 1,
    },
    task:{
        fontFamily: FONTS.Futura.semiBold,
        marginHorizontal: _scale(20),
        // marginBottom: _scale(15),
        color: COLORS.black,
        flex: 1,
    }

})