import { StatusBar, } from 'react-native'
import React from 'react'
import { createStackNavigator } from '@react-navigation/stack';
import Login from '../../screens/auth/Login'
import { ROUTES } from '../../constants'

const Stack = createStackNavigator()


function AuthNavigator() {
    return (
        <>
            <StatusBar backgroundColor={'blue'}></StatusBar>
            <Stack.Navigator>
                <Stack.Screen
                    name={ROUTES.LOGIN}
                    component={Login}
                    options={{ headerShown: false }} />
            </Stack.Navigator>

        </>
    )
}

export default AuthNavigator