import { END_POINTS } from "../../constants";
import { API } from "../../services";
import FlashMessage from "../../utils/flashMessage";
var api = API.getInstance();

export const loginApi = async (request) => new Promise((resolve, reject) => {
    console.log('Login APi PArameters',request);

    api.onPostApi(END_POINTS.login, request).then((responseData) => {
        resolve(responseData.json)
    }).catch((error) => {
        console.log(error,'EReroerp');
        FlashMessage(error.msg, '1')
        reject(error)
    })
});
