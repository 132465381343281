import moment from "moment";
import { FONTS, ROUTES } from "../constants";
import { appStyles } from "../styles/appStyles";
import { memCellStyles } from "../styles/memDetailCellStyles";
import { decodeHtmlentities } from "../utils";
import { Text, TouchableOpacity, View } from 'react-native'

 export default function MemberDetailCell(props) {
   
    const dateString = props.item.date
    const formattedDate = moment(dateString).format('MMM DD,YYYY')
    let t = 0
    props.item.details.map((i) => t += parseFloat(i.worked_hours))
    return (
        <View style={appStyles.mainView}>
            <View style={memCellStyles.cardView}>
                <View style={memCellStyles.cardInnerView}>
                    <Text style={memCellStyles.headings}>{formattedDate}</Text>
                    <Text style={[memCellStyles.headings, { fontFamily: FONTS.Futura.semiBold }]}>{t} {t > 1 ? 'Hrs' : 'Hr'}</Text>
                </View>
                <View style={[memCellStyles.divider]} />
                {props.item.details.map((object, index) => {
                    const description = decodeHtmlentities(object.description_3).replace(/&nbsp;/g, ' ')   
                    const isLastItem = index === props.item.details.length - 1;
                    return (
                        <View style={appStyles.mainView}>
                            <TouchableOpacity style={memCellStyles.innerView} onPress={()=>{props.navigation.push(ROUTES.PROJECTSUMMARY,{id:object.id,name:object.name})}}>
                                <Text style={memCellStyles.content}>
                                <Text style={{textDecorationLine:'underline',lineHeight:20}}>{object.name}</Text>
                                <Text> - </Text>
                                <Text>{object.name_3}</Text>
                                </Text>
                                <Text style={memCellStyles.workedHrs}>{object.worked_hours} {object.worked_hours > 1 ? 'hrs' : 'hr'}</Text>
                            </TouchableOpacity>
                            <View style={memCellStyles.innerDesView}>
                                <Text style={memCellStyles.description}>{((description.replace(/\s+/g, ' ').trim()).replace(/<[^>]+>/g, '')).replace(/&rsquo;/g, "'")}</Text>
                            </View>
                            {!isLastItem && <View style={[memCellStyles.divider]} />}
                        </View>
                    )
                })}

            </View>
        </View>
    )
}
