import { END_POINTS } from "../constants";
import EmployeeRes from "../models/TeamsListModel";
import { API } from "../services";
import { mergeRowsColumns } from "../utils";
var api = API.getInstance()

export const getTeamList = async (request) => new Promise((resolve, reject) => {
    api.onPostApi(END_POINTS.getList, request).then((response) => {    
        const res = response.json.data
        const data = mergeRowsColumns(res)
        // console.log(data,"Data==============")
        let responceModel = new EmployeeRes().initWithRes(data)
        resolve(responceModel)
    }).catch((error) => {
        reject(error)
    })
})