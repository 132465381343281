import { END_POINTS } from "../../constants"
import User from "../../models/CurrentUserModel"
import { API } from "../../services"
import { getIdFromStorage } from "../../services/storage/StorgeHelper"
var apiManager = API.getInstance()

export const getCurrentUser = async () => new Promise((resolve, reject) => {
    apiManager.onGetApi(END_POINTS.getCurrUser).then((response) => {
        let responceModel = new User(response["json"])
        resolve(responceModel)
    }).catch((error) => {
        console.log('---- error', error);
        reject(error)
    })
}) 