export default {
    appBlue:'#1B3E61',
    light:'#F3F8FE',
    transparentGrey:'#75787F',
    white:'white',
    skyBlue:'#00C0F7',
    black:'#292929',
    purple:'#573BFF',
    grey:'#75787F',
    lightGreen:'#DCFEEC',
    parrotGreen:'#AADD22',
    darkGreen:'#0D9834',
    lightOrange:'rgba(247, 144, 63, 0.15)',
    darkOrange:'rgba(220, 102, 10, 1)',
    lightBlue:'rgba(224, 242, 255, 1)',
    darkBlue:'rgba(41, 98, 155, 1)',
    tomatoRed:'#FF5447',
    lightgrey:'#E1E1E1',
    divider:'#DFEEFF',
    transparent: 'rgba(0, 0, 0, 0.2)',
    transparentDark:'rgba(0, 0, 0, 0.6)',
    lighterblack: 'rgba(232, 238, 244, 0.93)',
    lighterBlue:'#82C9FC',
    orange:'orange',
    selectedColor:'#E1F2FF'
   


}