import { showMessage } from 'react-native-flash-message'
import { Platform } from 'react-native'
import {  FONTS } from "../../constants";
import { _scale } from '..';


const FlashMessage = (message, type = '0') => {
    showMessage({
        message: "Analytics Metabase",
        description: message,
        type: type == '0' ? 'success' : 'danger',
        duration: 5000,
        icon: 'auto',
        statusBarHeight: Platform.OS === 'ios' ? _scale(20) : _scale(15),
        style: { paddingTop: Platform.OS === 'ios' ? _scale(30) : _scale(5) },
        titleStyle: { fontFamily: FONTS.Futura.semiBold },
        textStyle: { fontFamily: FONTS.Futura.book }
    });
}

export default FlashMessage;