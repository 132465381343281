
import { ROUTES } from '../../constants';
import Home from '../../screens/dashboard/home/Home';
import Teams from '../../screens/dashboard/teams/Teams';
import { createStackNavigator } from '@react-navigation/stack';
import Projects from '../../screens/dashboard/projects/Projects';
import MemberDetail from '../../screens/dashboard/memberDetails/MemberDetail';
import ProjectSummary from '../../screens/dashboard/projectDetails/ProjectSummary'
import MissingStatus from '../../screens/dashboard/missingStatus/MissingStatus'

const Stack = createStackNavigator()

export default function DashboardNavigator() {

    return (<Stack.Navigator>
        <Stack.Screen
            name={ROUTES.HOME}
            component={Home}
            options={{ headerShown: false }} />
        <Stack.Screen
            name={ROUTES.TEAMS}
            component={Teams}
            options={{ headerShown: false }} />
        <Stack.Screen
            name={ROUTES.EMPDETAILS}
            component={MemberDetail}
            options={{ headerShown: false }} />
       
        <Stack.Screen
            name={ROUTES.PROJECTS}
            component={Projects}
            options={{ headerShown: false }} />
      
        <Stack.Screen
            name={ROUTES.PROJECTSUMMARY}
            component={ProjectSummary}
            options={{ headerShown: false }} />
         <Stack.Screen
            name={'Missing'}
            component={MissingStatus}
            options={{ headerShown: false }} />
        
    </Stack.Navigator>
    )
}