import { scale, verticalScale } from 'react-native-size-matters'
import { Platform } from 'react-native'

export const _scale = (number) => {
  return Platform.OS === 'ios' ? scale(number) : number
}

export const _verticalScale = (number) => {
  return Platform.OS === 'ios' ? verticalScale(number) : number
}

export const mergeRowsColumns = (data) => {

  const rows = data.rows
  const cols = data.cols
  const result = []

  rows.forEach(row => {
    const obj = {}
    cols.forEach((col, index) => {
      obj[col.name] = row[index]
    })
    result.push(obj)
  })

  return result

}


export function getInitials(name) {
  var words = name.split(" ");
  var firstTwoWords = words.slice(0, 2);

  // use the map() method to return an array of the first letters of each word
  var initialsArray = firstTwoWords.map(word => word.charAt(0));

  // use the join() method to combine the letters into a single string
  var initials = initialsArray.join("");

  // return the initials in uppercase
  return initials.toUpperCase();
}
export function decodeHtmlentities(text) {
  const entities = [
    ['amp', '&'],
    ['apos', '\''],
    ['#x27', '\''],
    ['#x2F', '/'],
    ['#39', '\''],
    ['#47', '/'],
    ['lt', '<'],
    ['gt', '>'],
    ['nbsp', ' '],
    ['quot', '"'],
    ['ldquo', '“'],
    ['rdquo','”']
  ];

  for (let i = 0, max = entities.length; i < max; ++i) {
    text = text.replace(new RegExp(`&${entities[i][0]};`, 'g'), entities[i][1]);
  }

  return text;
}

