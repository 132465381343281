import { Platform, StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import React from 'react'
import { COLORS, FONTS, ICONS } from '../constants'
import { _scale } from '../utils'
export default function BackHeader(props) {
 
  return (
 
      <View style={[styles.container]}>
        <TouchableOpacity style={{justifyContent:'center',padding:_scale(10)}}onPress={() => props.navigation.pop()}>
          <ICONS.Ionicons name='md-chevron-back' size={_scale(24)} color={COLORS.white}  />
        </TouchableOpacity>
        
        <View style={{alignItems:'center',padding:_scale(10)}}>
          <Text style={styles.text}>{props.title}</Text>
        </View>
       <View  style={{alignItems:'center', padding:_scale(10),}} >
        {props.third && Platform.OS!=='web'&&
        <TouchableOpacity style={styles.iconView} onPress={props.onPressPdf}>
          <ICONS.AntDesign name='download' size={_scale(20)} color={COLORS.white} style={{alignItems:'center',}}/>
        </TouchableOpacity>
        }
      </View>
      </View>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: COLORS.appBlue,
    width:'100%',
    justifyContent:'space-between',
    flexDirection:'row',
    alignItems:'center'
  },
  text: {
    color: COLORS.white,
    fontSize: _scale(20),
    fontFamily:FONTS.Futura.medium
  },
  
  iconView: {
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: _scale(10),
    marginTop:_scale(5)
  }


})