import { StyleSheet } from "react-native"
import { _scale } from "../utils"
import { COLORS, FONTS } from "../constants";


export const teamListStyles = StyleSheet.create({
    mainBtn:{ 
        flex: 1, 
        borderBottomWidth: 1, 
        borderBottomColor: COLORS.lightgrey, 
        borderLeftColor:COLORS.skyBlue,
        marginBottom: _scale(10) ,
      
    },
    container: {
        flexDirection: 'row',   
        borderColor: COLORS.lightgrey,
        flex: 1,
    },
    avatarView: {
        marginTop: _scale(10),
        borderRadius: _scale(10),
        backgroundColor: COLORS.lightgrey
    },
    avatar: {
        borderRadius: _scale(10),
        height:"100%",
        width:"100%",

       
    },
    nameView: {
        padding: _scale(5),
        flex: 1,
       
    },
    profileIcon: {
        height: _scale(50),
        width: _scale(50),
        backgroundColor: COLORS.lightgrey,
        borderRadius: _scale(10),
        marginTop: _scale(11),
        alignItems: 'center',
        justifyContent: 'center',

    },
    names: {
        fontFamily: FONTS.Futura.medium,
        fontSize: _scale(16),
        color: COLORS.black,
        marginVertical:_scale(5),
        marginLeft: _scale(10),

    },
    statusView: {
        borderWidth: 1,
        borderColor: COLORS.white,
        height: _scale(10),
        borderRadius: _scale(70),
        bottom: 0,
        width: _scale(10),
        right: 0
    },
    technology:{ 
        fontFamily: FONTS.Futura.medium, 
        marginTop: _scale(10), 
        color: COLORS.black 
    },
    
})