import { END_POINTS } from "../constants";
import ProjectDetailRes from "../models/projectDetailModel";
import { API } from "../services";
import { mergeRowsColumns } from "../utils";
var api = API.getInstance()
 
export const projectDetailApi = async (request) => new Promise((resolve, reject) => {
    api.onPostApi(END_POINTS.getList, request).then((response) => {
        const data = mergeRowsColumns(response.json.data)
        let responceModel = new ProjectDetailRes().initWithRes(data)
        resolve(responceModel)
    }).catch((error) => {
        reject(error)
    })
})