import { Platform, StyleSheet } from "react-native";
import { _scale } from "../utils";
import { COLORS,FONTS } from "../constants";
export const appStyles = StyleSheet.create({
    safeAreaStyle: {
        flex: 1,
    },
    mainView: {
        flex: 1,
    },
    safeAreaMainView: {
        flex: 1,
        backgroundColor: COLORS.appBlue
    },
    dividerView: {
        borderTopRightRadius: _scale(22),
        borderTopLeftRadius: _scale(22),
        backgroundColor: COLORS.transparentGrey,
        marginHorizontal: _scale(15),
    },
    noDataView:{
        alignItems:'center',
        justifyContent:'center',
        margin:_scale(20),
      
    },
   
    noDataFound:{
        fontFamily:FONTS.Futura.semiBold,
        fontSize:_scale(17),
        color:COLORS.black,
        textAlign:'center'
    }

})