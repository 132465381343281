import { Dimensions, Image, Platform, View } from 'react-native'
import { appStyles } from '../styles/appStyles'
import React from 'react'
import { _scale } from '../utils'

export default function AppDivider() {
    const { height,width } = Dimensions.get('screen')
   
   return (
  <>
    <View style = {{alignItems: 'center'}}>
      {Platform.OS=='ios' &&  <Image style={{width: width*0.92}} source={require('../assets/images/containerDivider/containerDivider.png')}/> } 
    </View>  
     {Platform.OS == 'android'&& <View style={[appStyles.dividerView,{height:_scale(15)}]}/>}
   </>
  )
}

