import { StyleSheet, Text, View,TouchableOpacity,ImageBackground } from 'react-native'
import React from 'react'
import IMAGES from '../assets'
import { _scale } from '../utils'
import { COLORS, ICONS, STRINGS,FONTS } from '../constants'

export default function Card(props) {
  return (
    <TouchableOpacity style={[styles.cardView,{marginBottom:_scale(20)}]} onPress={props.onPress}>
    <ImageBackground source={IMAGES.stars} style={styles.imgBack} >
      <View style={[styles.iconView, { backgroundColor: COLORS.purple }]}>
        <ICONS.AntDesign name='filetext1' size={24} color={COLORS.white} />
      </View>
      <View style={styles.cardTitleView}>
        <View>
          <Text style={styles.cardTitle}>{'Missing Status'}</Text>
          <Text style={styles.subText}>{STRINGS.missingSubText}</Text>
        </View>
        <View style={styles.arrowView}>
          <ICONS.AntDesign name='arrowright' size={24} color={COLORS.white} />
        </View>
      </View>
    </ImageBackground>
  </TouchableOpacity>
  )
}

const styles = StyleSheet.create({
    cardView: {
        padding: _scale(20),
        backgroundColor: COLORS.white,
        marginHorizontal: _scale(20),
        marginTop: _scale(20),
        borderRadius: _scale(23),
    
        height:_scale(170)
    

},
cardTitleView: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop:_scale(7)
    
},
cardTitle: {
    fontSize: _scale(28),
    color: COLORS.black,
    fontFamily: FONTS.Futura.semiBold,
   

},
iconView: {
    backgroundColor: COLORS.skyBlue,
    borderRadius: _scale(10),
    width: _scale(45),
    height: _scale(45),
    alignItems: 'center',
    justifyContent: 'center'
},
arrowView: {
    backgroundColor: COLORS.black,
    borderRadius: _scale(10),
    width: _scale(40),
    height: _scale(40),
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: _scale(8)
},
subText: {
    color: COLORS.black,
    fontFamily: FONTS.Futura.medium,
    fontSize:_scale(16)
},
})