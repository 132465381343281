import { END_POINTS } from "../constants"
import ProjectListRes from "../models/ProjectListModel"
import { API } from "../services"
import { mergeRowsColumns } from "../utils"
var api = API.getInstance()
 
export const getProjectNameApi = async (request) => new Promise((resolve, reject) => {
    api.onPostApi(END_POINTS.getList, request).then((response) => {
       const res = response.json.data
       const data = mergeRowsColumns(res)
    //    console.log(data,"Sata");
      let responceModel = new ProjectListRes().initWithRes(data)        
      resolve(responceModel)
    }).catch((error) => {
        reject(error)
    })
})