import { StyleSheet, View } from 'react-native'
import Root from './src'
import { useFonts } from 'expo-font'
import { useCallback } from 'react'
import SplashScreen from 'expo-splash-screen'

export default function App() {
  const [fontsLoaded] = useFonts({
    'FuturaBold': require('./assets/fonts/FuturaCyrillicBold.ttf'),
    'FuturaSemiBold': require('./assets/fonts/FuturaCyrillic-Demi.ttf'),
    'FuturaMedium': require('./assets/fonts/FuturaCyrillicMedium.ttf'),
    'book':require('./assets/fonts/FuturaCyrillicBook.ttf'),
    'FuturaHeavy':require('./assets/fonts/FuturaCyrillicHeavy.ttf'),
 
  })

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      await console.log('sg');
    }
  }, [fontsLoaded])

  if (!fontsLoaded) {
    return null
  }

  return (
    <View onLayout={onLayoutRootView} style={styles.container}>
      <Root/>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    
  },
})
