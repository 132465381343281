import { Dimensions, FlatList, Text, View, Image,Linking, TouchableOpacity, Alert } from 'react-native'
import {  requestFunctions, teamDetailWithID } from '../../../utils/requestParams'
import { useSafeAreaInsets } from 'react-native-safe-area-context'
import MemberDetailCell from '../../../components/MemberDetailCell'
import { memDetailStyles } from '../../../styles/userDetailStyles'
import { getTaskList } from '../../../controllers/postTaskList'
import { getTeamList } from '../../../controllers/postTeamList'
import { COLORS, ICONS, STRINGS } from '../../../constants'
import { htmlUser } from '../../../constants/appStrings'
import BackHeader from '../../../components/BackHeader'
import AppDivider from '../../../components/AppDivider'
import FlashMessage from '../../../utils/flashMessage'
import APILoader from '../../../components/APILoader'
import { appStyles } from '../../../styles/appStyles'
import RNHTMLtoPDF from 'react-native-html-to-pdf'
import { useEffect, useState } from 'react'
import { _scale } from '../../../utils'

export default function MemberDetail({ navigation, route }) {

    const [data, setData] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const[name,setName] = useState(route.params.routeName == 'Team'? route.params.item.name:'')
    const[mobile,setMobile] = useState(route.params.routeName == 'Team'?route.params.item.mobile:'')
    const[email,setEmail] = useState(route.params.routeName == 'Team'?route.params.item.email:'')
    const[photo,setPhoto] = useState(route.params.routeName == 'Team'?route.params.item.photo:'')
    const[skype,setSkype] = useState(route.params.routeName == 'Team'?route.params.item.skype:'')
    const[active,setActive] = useState(route.params.routeName == 'Team'?route.params.item.active:'')
    const id = route.params.id
    

     
   
       
    useEffect(() => {
        setIsLoading(true)
       if(route.params.routeName == 'Project') { 
        getTeamList(teamDetailWithID(id)).then((res) => {
            setMobile(res[0].mobile)
            setActive(res[0].active)
            setEmail(res[0].email)
            setSkype(res[0].skype)
            setPhoto(res[0].photo)
            setName(res[0].name)  
          }).catch((err) => {
                    console.log(err)
            }).finally(() => {
                  
            })
        }
        let request = requestFunctions(id, active)
        getTaskList(request).then(res => {
            const response = res
            const combinedData = response.reduce((acc, cur) => {
                const dateString  = String(cur.created_at_3).substring(0,10)
                const existingData = acc.find((elem) => elem.date === dateString);
                if (existingData) {
                    existingData.details.push(cur);
                } else {
                    acc.push({ date: dateString, details: [cur] });
                }
                return acc;
            }, [])
            setData(combinedData) 
            

        }).catch(() => {

        }).finally(() => {
            setIsLoading(false)
        })
          
    }, [navigation])

    const formatMobileNumber = (mobileNumber) => {
        const cleanedNumber = mobileNumber.replace(/\D/g, '')    
        if(cleanedNumber.length == 11) {
            const countryCode = '91'
            const areaCode = cleanedNumber.slice(1, 6)
            const lineNumber = cleanedNumber.slice(6)
            return `+${countryCode}${areaCode}${lineNumber}`
        }
        else if(cleanedNumber.length > 10){
        const countryCode = cleanedNumber.slice(0, 2)
        const areaCode = cleanedNumber.slice(2, 7)
        const lineNumber = cleanedNumber.slice(7)
        return `+${countryCode}${areaCode}${lineNumber}`
        }
        else if(cleanedNumber.length == 10) {
            const countryCode = '91'
            const areaCode = cleanedNumber.slice(0, 5)
            const lineNumber = cleanedNumber.slice(5)
            return `+${countryCode}${areaCode}${lineNumber}`
            }
      }

    const renderItem = (itemData) => {
        return (
            <MemberDetailCell item={itemData.item} navigation={navigation}/>
        )
    }
    async function createPDF() {
            try {
            setIsLoading(true);
            let options = {
                html: htmlUser(data,photo,name,email,mobile),
                fileName: 'Employee_Report',
                directory: 'Documents',
            };

            let file = await RNHTMLtoPDF.convert(options);
            setIsLoading(false);
            console.log(file.filePath);

            let shareOptions = {
                url: `file://${file.filePath}`,
                type: 'application/pdf',
                message: 'Employee Report',
                title: 'Share PDF',
            };
            
          
            
            Alert.alert(
                'PDF Downloaded',
                `File path: ${file.filePath}`,
                [
                {
                    text: 'OK',
                    onPress: () => console.log('OK pressed'),
                },
                {
                    text:'Share',
                    // onPress:async()=>{  
                    //     const shareResult = await Share.open(shareOptions);
                    //     console.log(shareResult)
                    // }
                }
                ],
            );
            } catch (error) {
            console.log(error);
            setIsLoading(false);
            Alert.alert('Error', error.message);
            }
                }
    
 
    const onPressEmail = () => {
        const emailUrl =    `mailto:${email}`
         Linking.openURL(emailUrl)
    }
    const onPressPhone = () => {
        const phoneUrl = `tel:${formatMobileNumber(mobile)}`
        Linking.openURL(phoneUrl)
       
        
    }
   const onPressSkypeId = () => {
     const skypeUrl =   `skype:${skype}?chat`
     Linking.canOpenURL(skypeUrl).then((supported)=>{
        console.log("Supported Skype",supported)
         if(supported){
             Linking.openURL(skypeUrl).then((res)=>{
                 console.log(res)
             }).catch((err) => {    
                 FlashMessage(STRINGS.appNotSupported,'1')
             })
         }
        else{
             console.log("Unable  to open app ")
             FlashMessage(STRINGS.appNotSupported,'1')
        }
     }).catch((err) => {
            console.log(err,"ERRRRRROR PHONE")
     })
    }
    
   
    const {top} = useSafeAreaInsets()

    return (
        <View style={appStyles.safeAreaMainView}>
        <View style={[memDetailStyles.mainView,{paddingTop:top}]}>
            {isLoading && <APILoader />}
            <View>
                <BackHeader title={"Profile"} navigation={navigation} third onPressPdf={createPDF}/>
                <View style={[memDetailStyles.secondView]}>
                    {(photo !== null && String(photo).length > 0) ?
                        <View style={memDetailStyles.imgView}>
                            <Image source={{ uri: STRINGS.imgUri + photo }} style={memDetailStyles.image} resizeMode='cover' />
                        </View>
                        : <View style={memDetailStyles.imageUser}>
                            <ICONS.AntDesign name='user' size={40} color={COLORS.black} />
                        </View>}
                    <View style = {memDetailStyles.userNameView}>
                        <Text style = {memDetailStyles.userName}>{name}</Text>
                        <TouchableOpacity style = {memDetailStyles.btns}>
                          <Text style = {[memDetailStyles.subUserName]} onPress={onPressEmail}>{email}</Text>
                        </TouchableOpacity>
                        {mobile.length !== 0 && <TouchableOpacity style={memDetailStyles.btns}>
                            <Text style = {memDetailStyles.subUserName} onPress={onPressPhone}>{formatMobileNumber(mobile)} </Text>
                        </TouchableOpacity>}
                        {skype.length !== 0 &&<TouchableOpacity style={memDetailStyles.btns} >
                            <Text style={memDetailStyles.subUserName}  onPress={onPressSkypeId}>{skype}</Text>
                        </TouchableOpacity>}
                    </View>
                </View>
            </View>
            <View style={{flex:1,marginTop:_scale(30)}}>
                <AppDivider/>
                <View style={memDetailStyles.thirdView}>
                    <Text style={memDetailStyles.title}>{STRINGS.userTimeReport}</Text>
                    {data.length == 0 && isLoading == false && <View style={memDetailStyles.noDataView}>
                        <Text style={memDetailStyles.noData}>{STRINGS.noData}</Text>
                    </View>
                    }
                    <FlatList
                        data={data}
                        style={memDetailStyles.flatlist}   
                        renderItem={renderItem}
                        keyExtractor={(item) => item.id_5}     
                    />
                </View>
            </View>
        </View>
        </View>
    )
}


