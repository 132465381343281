export default class TaskRes {
    constructor() { }
    initWithRes(resArr) {
        let taskList = []
        resArr.forEach(element => {
            taskList.push(new Tasklist().initWithResObj(element))
        });
        return taskList
    }
}

class Tasklist {
    constructor(
        active,
        android,
        assigned_to,
        background_color,
        closed_date,
        created_at,
        created_at_2,
        created_at_3,
        created_by,
        created_by_2,
        created_by_3,
        default_value,
        departments,
        description,
        description_2,
        description_3,
        design,
        discussion_id,
        dotnet,
        due_date,
        due_date_2,
        estimated_time,
        google_doc_link,
        handoff_doc_link,
        id,
        id_2,
        id_3,
        id_4,
        id_5,
        id_6,
        in_trash,
        in_trash_2,
        in_trash_3,
        in_trash_date,
        in_trash_date_2,
        in_trash_date_3,
        ios,
        is_public,
        java,
        last_comment_date,
        last_comment_date_2,
        name,
        name_2,
        name_3,
        name_4,
        name_5,
        notes,
        order_tasks_by,
        parent_id,
        php,
        progress,
        progress_2,
        projects_groups_id,
        projects_id,
        projects_phases_id,
        projects_priority_id,
        projects_status_id,
        projects_types_id,
        sort_order,
        sort_order_2,
        start_date,
        start_date_2,
        status_group,
        tasks_groups_id,
        tasks_id,
        tasks_label_id,
        tasks_labels_id,
        tasks_priority_id,
        tasks_priority_id_2,
        tasks_status_id,
        tasks_status_id_2,
        tasks_type_id,
        tasks_types_id,
        tasks_view,
        team,
        tickets_id,
        togo_hours,
        togo_hours_2,
        versions_id,
        work_hours,
        worked_hours
    ) {
        this.active = active;
        this.android = android;
        this.assigned_to = assigned_to;
        this.background_color = background_color;
        this.closed_date = closed_date;
        this.created_at = created_at;
        this.created_at_2 = created_at_2;
        this.created_at_3 = created_at_3;
        this.created_by = created_by;
        this.created_by_2 = created_by_2;
        this.created_by_3 = created_by_3;
        this.default_value = default_value;
        this.departments = departments;
        this.description = description;
        this.description_2 = description_2;
        this.description_3 = description_3;
        this.design = design;
        this.discussion_id = discussion_id;
        this.dotnet = dotnet;
        this.due_date = due_date;
        this.due_date_2 = due_date_2;
        this.estimated_time = estimated_time;
        this.google_doc_link = google_doc_link;
        this.handoff_doc_link = handoff_doc_link;
        this.id = id;
        this.id_2 = id_2;
        this.id_3 = id_3;
        this.id_4 = id_4;
        this.id_5 = id_5;
        this.id_6 = id_6;
        this.in_trash = in_trash;
        this.in_trash_2 = in_trash_2;
        this.in_trash_3 = in_trash_3;
        this.in_trash_date = in_trash_date;
        this.in_trash_date_2 = in_trash_date_2;
        this.in_trash_date_3 = in_trash_date_3;
        this.ios = ios;
        this.is_public = is_public;
        this.java = java;
        this.last_comment_date = last_comment_date;
        this.last_comment_date_2 = last_comment_date_2;
        this.name = name;
        this.name_2 = name_2;
        this.name_3 = name_3;
        this.name_4 = name_4;
        this.name_5 = name_5;
        this.notes = notes;
        this.order_tasks_by = order_tasks_by;
        this.parent_id = parent_id;
        this.php = php;
        this.progress = progress;
        this.progress_2 = progress_2;
        this.projects_groups_id = projects_groups_id;
        this.projects_id = projects_id;
        this.projects_phases_id = projects_phases_id;
        this.projects_priority_id = projects_priority_id;
        this.projects_status_id = projects_status_id;
        this.projects_types_id = projects_types_id;
        this.sort_order = sort_order;
        this.sort_order_2 = sort_order_2;
        this.start_date = start_date;
        this.start_date_2 = start_date_2;
        this.status_group = status_group;
        this.tasks_groups_id = tasks_groups_id;
        this.tasks_id = tasks_id;
        this.tasks_label_id = tasks_label_id;
        this.tasks_labels_id = tasks_labels_id;
        this.tasks_priority_id = tasks_priority_id;
        this.tasks_priority_id_2 = tasks_priority_id_2;
        this.tasks_status_id = tasks_status_id;
        this.tasks_status_id_2 = tasks_status_id_2;
        this.tasks_type_id = tasks_type_id;
        this.tasks_types_id = tasks_types_id;
        this.tasks_view = tasks_view;
        this.team = team;
        this.tickets_id = tickets_id;
        this.togo_hours = togo_hours;
        this.togo_hours_2 = togo_hours_2;
        this.versions_id = versions_id;
        this.work_hours = work_hours;
        this.worked_hours = worked_hours;
    }


    initWithResObj(response) {
        this.active = response["active"]||'';
        this.android = response["android"]||'';
        this.assigned_to = response['assigned_to']||'';
        this.background_color = response['background_color']||'';
        this.closed_date = response['closed_date']||''
        this.created_at = response['created_at']||''
        this.created_at_2 = response['created_at_2']||''
        this.created_at_3 = response['created_at_3']||''
        this.created_by = response['created_by']||''
        this.created_by_2 = response['created_by_2']||''
        this.created_by_3 = response['created_by_3']||''
        this.default_value = response['default_value']||''
        this.departments = response['departments']||''
        this.description = response['description']||''
        this.description_2 = response['description_2']||''
        this.description_3 = response['description_3']||''
        this.design = response['design']||''
        this.discussion_id = response['discussion_id']||''
        this.dotnet = response['dotnet']||''
        this.due_date = response['due_date']||''
        this.due_date_2 = response['due_date_2']||''
        this.estimated_time = response['estimated_time']||''
        this.google_doc_link = response['google_doc_link']||''
        this.handoff_doc_link = response['handoff_doc_link']||''
        this.id = response['id']||''
        this.id_2 = response['id_2']||''
        this.id_3 = response['id_3']||''
        this.id_4 = response['id_4']||''
        this.id_5 = response['id_5']||''
        this.id_6 = response['id_6']||''
        this.in_trash = response['in_trash']||''
        this.in_trash_2 = response['in_trash_2']||''
        this.in_trash_3 = response['in_trash_3']||''
        this.in_trash_date = response['in_trash_date']||''
        this.in_trash_date_2 = response['in_trash_date_2']||''
        this.in_trash_date_3 = response['in_trash_date_3']||''
        this.ios = response['ios']||''
        this.is_public = response['is_public']||''
        this.java = response['java']||''
        this.last_comment_date = response['last_comment_date']||''
        this.last_comment_date_2 = response['last_comment_date_2']||''
        this.name = response['name']||''
        this.name_2 = response['name_2']||''
        this.name_3 = response['name_3']||''
        this.name_4 = response['name_4']||''
        this.name_5 = response['name_5']||''
        this.notes = response['notes']||''
        this.order_tasks_by = response['order_tasks_by']||''
        this.parent_id = response['parent_id']||''
        this.php = response['php']||''
        this.progress = response['progress']||''
        this.progress_2 = response['progress_2']||''
        this.projects_groups_id = response['projects_groups_id']||''
        this.projects_id = response['projects_id']||''
        this.projects_phases_id = response['projects_phases_id']||''
        this.projects_priority_id = response['projects_priority_id']||''
        this.projects_status_id = response['projects_status_id']||''
        this.projects_types_id = response['projects_types_id']||''
        this.sort_order = response['sort_order']||''
        this.sort_order_2 = response['sort_order_2']||''
        this.start_date = response['start_date']||''
        this.start_date_2 = response['start_date_2']||''
        this.status_group = response['status_group']||''
        this.tasks_groups_id = response['tasks_groups_id']||''
        this.tasks_id = response['tasks_id']||''
        this.tasks_label_id = response['tasks_label_id']||''
        this.tasks_labels_id = response['tasks_labels_id']||''
        this.tasks_priority_id = response['tasks_priority_id']||''
        this.tasks_priority_id_2 = response['tasks_priority_id_2']||''
        this.tasks_status_id = response['tasks_status_id']||''
        this.tasks_status_id_2 = response['tasks_status_id_2']||''
        this.tasks_type_id = response['tasks_type_id']||''
        this.tasks_types_id = response['tasks_types_id']||''
        this.tasks_view = response['tasks_view']||''
        this.team = response['team']||''
        this.tickets_id = response['tickets_id']||''
        this.togo_hours = response['togo_hours']||''
        this.togo_hours_2 = response['togo_hours_2']||''
        this.versions_id = response['versions_id']||''
        this.work_hours = response['work_hours']||''
        this.worked_hours = response['worked_hours']||''
        return this;
    }
}