export default class EmployeeRes {
  constructor() { }

  initWithRes(resArr) {
    let employeeList = [];
    resArr.forEach(element => {
      employeeList.push(new Employee().initWithResObj(element));
    });
    return employeeList;
  }
}

class Employee {
  constructor(active, email, id, last_status, name, no_of_status, photo, technology, skype, mobile) {
    this.active = active;
    this.email = email;
    this.id = id;
    this.last_status = last_status;
    this.name = name;
    this.no_of_status = no_of_status;
    this.photo = photo;
    this.technology = technology;
    this.skype = skype;
    this.mobile = mobile;
  }

  initWithResObj(response) {
    this.active = response['active'] || ''
    this.email = response['email'] || ''
    this.id = response['id'] || ''
    this.last_status = response['last_status'] || ''
    this.name = response['name'] || ''
    this.no_of_status = response['no_of_status'] || ''
    this.photo = response['photo'] || ''
    this.technology = response['technology'] || ''
    this.skype = response['skype'] || ''
    this.mobile = response['mobile'] || ''
    return this;
  }
}