import { useEffect } from 'react';
import FlashMessage from 'react-native-flash-message';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import { Platform, StatusBar } from 'react-native';
import AuthProvider from './context/AuthContextProvider';
import AppNavigation from './navigation';
import { COLORS } from './constants';
import { LOCAL_STORAGE } from './services';

function Root() {


    useEffect(() => {
      
        LOCAL_STORAGE.sync()
    }, [])

    return (
        <SafeAreaProvider>
            <AuthProvider>
                <StatusBar backgroundColor={'blue'} barStyle={'light-content'} translucent={Platform.OS ==='ios'} />
                <AppNavigation />
                <FlashMessage position="top" />
            </AuthProvider>
        </SafeAreaProvider>
    );
}

export default Root;
